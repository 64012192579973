import React from "react"

const OrderReview = () => {
  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div
            className="row align-items-center text-center"
            style={{ justifyContent: "center" }}
          >
            <div
              className="col-md-12"
              style={{
                backgroundColor: "#ffc45f",
                width: "500px",
                padding: "50px",
                textAlign: "center",
                margin: "20px",
              }}
            >
              <h3>A surprise for John Doe</h3>
              <div
                style={{
                  width: "300px",
                  height: "300px",
                  margin: "0 auto",
                  backgroundColor: "white",
                }}
              >
                <div className="row">
                  <div className="col-md-6">
                    <div>
                      <img src="/images/user/avatar-3.jpg" />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <span>Jo Malone</span>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div
                style={{
                  width: "400px",
                  height: "122px",
                  margin: "0 auto",
                  backgroundColor: "white",
                  padding: "10px",
                }}
              >
                <span>
                  Dear Jane, <br /> I wish you a happy birthday! Hope you like
                  the <br />
                  little gift. <br />
                  Love,
                  <br />
                  John
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OrderReview
