const ModuleConfig = {
  primaryKey: "id",
  formDefaultValue: {
    name: "",
    email: "",
    mobile: "",
    logo: "",
    merchant_detail: {
      business_name: "",
      abn_number: "",
      business_address: "",
      logo: "",
    },
  },
  formFields: {
    name: {
      label: "Name",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
    },
    email: {
      label: "Email",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
    },
    mobile: {
      label: "Mobile",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
    },
    password: {
      label: "Password",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "text",
      type: "password",
    },
    "merchant_detail.business_name": {
      label: "Business Name",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
    },
    "merchant_detail.abn_number": {
      label: "ABN number",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
    },
    "merchant_detail.business_address": {
      label: "Business address",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
    },
    "merchant_detail.logo": {
      label: "Logo",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "file",
      type: "file",
    },
    status: {
      label: "Status",
      isRequired: false,
      placeholder: "Select Status",
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      options: [
        {
          label: "Active",
          value: "Active",
        },
        {
          label: "Inactive",
          value: "Inactive",
        },
        {
          label: "Pending",
          value: "Pending",
        },
      ],
    },
  },
  listColumnLabel: {
    "merchant_detail.business_name": "Business Name",
    "merchant_detail.abn_number": "Business ABN",
    name: "User Name",
    mobile: "Mobile",
    email: "Email",
    status: "Status",
  },
  listColumnOrder: [
    "merchant_detail.business_name",
    "merchant_detail.abn_number",
    "name",
    "mobile",
    "email",
    "status",
  ],
}

export default ModuleConfig
