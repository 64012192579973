import { Formik } from "formik"
import AxiosInstance from "../utils/AxiosInstance"
import { useNavigate, Link } from "react-router-dom"
import "react-toastify/dist/ReactToastify.css"
import { ToastContainer, toast } from "react-toastify"
import * as Yup from "yup"
import BackBtn from "./common/BackBtn"

const PASSWORD_VALIDATOR = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*)(?=.{8,})/

const ChangePassword = () => {
  const navigate = useNavigate()


  const Schema = Yup.object().shape({
    currentPassword: Yup.string().required("Required field."),
    newPassword: Yup.string()
      .required("Required field.")
      .matches(
        PASSWORD_VALIDATOR,
        "New password must be at least 8 characters long with both letters and numbers."
      ),
    confirmPassword: Yup.string()
      .required("Required field.")
      .when("newPassword", {
        is: (val: string | any[]) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("newPassword")],
          "Your new passwords do not match."
        ),
      }),
  })
  const onsubmit = async (values: any) => {
    const data = {
      old_password: values.currentPassword,
      new_password: values.newPassword,
    }
    const res = await AxiosInstance.post("changepassword", data)
    if (res?.status === 200) {
      toast.success("Password updated Successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      })
      setTimeout(() => {
        localStorage.clear()
        navigate("/signin")
        window.location.reload()
      }, 1000)
    } else {
      toast.error("Please enter valid details.", {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }

  return (
    <>
      <div className="page-header">
        <ToastContainer />
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-11">
              <div className="page-header-title">
                <h5 className="m-b-10">Change Password</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <a>Change Password</a>
                </li>
              </ul>
            </div>
            {/* <BackBtn /> */}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>Change Password</h5>
            </div>
            <div className="card-body">
              <div className="row d-flex justify-content-center">
                <div className="col-md-5">
                  <Formik
                    initialValues={{
                      currentPassword: "",
                      newPassword: "",
                      confirmPassword: "",
                    }}
                    enableReinitialize={true}
                    validationSchema={Schema}
                    onSubmit={onsubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="input-group mb-3">
                          <input
                            className={
                              errors.currentPassword && touched.currentPassword
                                ? "form-control error"
                                : "form-control"
                            }
                            type="password"
                            name="currentPassword"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.currentPassword}
                            placeholder="Current Password"
                            autoFocus={true}
                          />
                        </div>
                        <div className="error-message">
                          {errors.currentPassword &&
                            touched.currentPassword &&
                            errors.currentPassword}
                        </div>
                        <div className="input-group  mb-4">
                          <input
                            className={
                              errors.newPassword && touched.newPassword
                                ? "form-control error"
                                : "form-control"
                            }
                            type="password"
                            name="newPassword"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.newPassword}
                            placeholder="New Password"
                          />
                        </div>
                        <div className="error-message">
                          {errors.newPassword &&
                            touched.newPassword &&
                            errors.newPassword}
                        </div>
                        <div className="input-group mb-4">
                          <input
                            className={
                              errors.confirmPassword &&
                              touched.confirmPassword &&
                              errors.confirmPassword
                                ? "form-control error"
                                : "form-control"
                            }
                            type="password"
                            name="confirmPassword"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirmPassword}
                            placeholder="Confirm Password"
                          />
                        </div>
                        <div className="error-message">
                          {errors.confirmPassword &&
                            touched.confirmPassword &&
                            errors.confirmPassword}
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary shadow-2 mb-4 mt-4"
                        >
                          Save
                        </button>
                        {/* </div>
                        </div> */}
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ChangePassword
