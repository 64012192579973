import {
  Route,
  Routes,
} from "react-router-dom";

import Home from "../modules/Home";
import CategoryRoute from "../modules/category/CategoryRoute";
import ProductRoute from "../modules/product/ProductRoute";
import BrandRoute from "../modules/brand/BrandRoute";
import UserRoute from "../modules/user/UserRoute";
import OrderRoute from "../modules/order/OrderRoute";
import MerchantRoute from "../modules/merchant/MerchantRoute";
import ContentRoute from "../modules/content/ContentRoute";
import Signin from "../modules/Signin";
import Signup from "../modules/Signup";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { appState } from "../stores/appSlice";
import LoginAs from './../modules/LoginAs';
import ChangePassword from './../modules/ChangePassword';
import OrderReciept from "../modules/OrderReciept";
import Summary from "../modules/Summary";
{
  /* Import New Entry */
}

/**
 * This will return all routes of the application
 */
const AppRoute = () => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const { isUserLoggedIn, profile } = useSelector(appState);

  useEffect(() => {
    if (isUserLoggedIn) {
      setToken(localStorage.getItem("token"));
    }
  }, [isUserLoggedIn]);

  if(!profile?.id && token){
    return <div>Loading...</div>
  }


  return (
    <>
      <Routes>
        <Route path="/signin" element={<Signin />} />
        <Route path="/order-reciept/:id/:name" element={<OrderReciept />} />
      </Routes>

      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              {/* <PrivateRoute> */}
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/home" element={<Home />} />
                  <Route path="/summary" element={<Summary />} />
                  {CategoryRoute}
                  {ProductRoute}
                  {BrandRoute}
                  {UserRoute}
                  {OrderRoute}
                  {MerchantRoute}
                  {ContentRoute}
                  <Route path="/change-password" element={<ChangePassword />} />
                  <Route path="/login-as/:token" element={<LoginAs />} />
                  {/* Add New Entry */}
                </Routes>
              {/* </PrivateRoute> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppRoute;
