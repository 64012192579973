import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import jwt from "jwt-decode"

const LoginAs = () => {

    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
       if(params.token){
          localStorage.setItem("token", params.token);
          try{
            const data = jwt(params.token);
            // dispatch(setUserDataOnInit(data));
            if(localStorage.getItem("update_password") == '1'){
              navigate("/change-password")
            }else{
              navigate("/home")
            }
            window.location.reload();
          }catch(e){
            navigate("/login")
          }
          
       }else{
        navigate("/login")
       }
        // 
    }, [dispatch, params])
    return (
        <>
        </>
    )
}

export default LoginAs