import { useEffect } from "react"
import { useLocation, useNavigate, Link } from "react-router-dom"
import { appState, getProfile } from "../../stores/appSlice"
import { useAppDispatch, useAppSelector } from "../../stores/hooks"
import AxiosInstance from "../../utils/AxiosInstance"
import config from "../../utils/config"

const Header = () => {
  const dispatchApp = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { profile } = useAppSelector(appState)

  var pageUrl = location.pathname.split("/")

  const token = localStorage.getItem("token")
  useEffect(() => {
    if (localStorage.getItem("update_password") == "1") {
      navigate("/change-password")
    }
    if (!token) {
      if (pageUrl[1] !== "order-reciept") {
        navigate("/signin")
      }
    } else {
      dispatchApp(getProfile())
    }
  }, [])

  if (pageUrl[1] === "order-reciept") {
    return <></>
  }

  const signout = async () => {
    const res = await AxiosInstance.get("signout")
    if (res?.status === 200) {
      localStorage.clear()
      navigate("/signin")
      window.location.reload()
    }
  }

  const NO_HEADER_MENU = [
    "/signin",
    "/signin/",
    "/signup",
    "/signup/",
    "/order-reciept",
    "/order-reciept/",
  ]

  return NO_HEADER_MENU.indexOf(location.pathname) === -1 ? (
    <header className="navbar pcoded-header navbar-expand-lg navbar-light ">
      <div className="m-header">
        <a className="mobile-menu" id="mobile-collapse" href="#!">
          <span></span>
        </a>
        <a href="/" className="b-brand">
          <img
            className="app-logo"
            src="/images/app-logo.png"
            alt="mobile-logo"
          />
          <span className="b-title">COUPID</span>
        </a>
      </div>
      <a className="mobile-menu" id="mobile-header" href="#!">
        <i className="feather icon-more-horizontal"></i>
      </a>
      <div className="collapse navbar-collapse">
        <ul className="navbar-nav ms-auto">
          <li>
            <div className="dropdown drp-user">
              <a
                href="#"
                className="dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="icon feather icon-user"></i>
              </a>
              <div className="dropdown-menu dropdown-menu-end profile-notification">
                <div className="pro-head">
                  <img
                    src={
                      profile?.logo
                        ? config.sourceImageURL + profile?.logo
                        : "../assets/images/user/avatar-1.jpg"
                    }
                    className={ profile?.logo ? "img-radius height-40"  :"img-radius"}
                    alt="User-Profile-Image"
                  />
                  <span>{profile?.name}</span>
                  <Link
                    to=""
                    className="dud-logout"
                    title="Logout"
                    onClick={() => {
                      signout()
                    }}
                  >
                    <i className="feather icon-log-out"></i>
                  </Link>
                </div>
                <ul className="pro-body">
                  <li>
                    <Link to="/change-password" className="dropdown-item">
                      <i className="feather icon-lock"></i> Change Password
                    </Link>
                  </li>
                  <li>
                    <Link
                      to=""
                      onClick={() => {
                        signout()
                      }}
                      className="dropdown-item"
                    >
                      <i className="feather icon-log-out"></i> Logout
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </header>
  ) : null
}

export default Header
