import { Col, Row } from "react-bootstrap";

const Footer = () => {
  return (
    <div className="footer bg-light p-2">
      <Row>
        <Col>All rights reserved &copy; cloudploys</Col>
      </Row>
    </div>
  );
};

export default Footer;
