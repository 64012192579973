import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../stores';
import { OrderDetailInterface } from './models/OrderDetailInterface';
import { createOrder, deleteOrder, getOrderDetail, getOrderList, updateOrder } from './OrderAPI';

export interface OrderStateInterface {
  orders: OrderDetailInterface[];
  favourites: OrderDetailInterface[];
  totalRecord: number;
  status: 'idle' | 'loading' | 'failed' | 'created' | 'updated' | 'deleted';
  detail: OrderDetailInterface,
}

const initialState: OrderStateInterface = {
  orders: [],
  favourites: [],
  totalRecord: 0,
  status: 'idle',
  detail: {
    id:"",
    order_number: "",
    user_id: "",
    merchant_id: "",
    name: "",
    address: "",
    status: "",
    note: "",
    order_type: "",
    mobile_number: "",
    totla_price: "",
    transaction_id: "",
    confirmation_code: "",
    order_link: "",
    link_expiry_date: "",
    redemption_date: "",
    products: "",
    user: "",
    merchant: "",
    delivery_detail :[],
    product_option_detail:[]
  }
};

export const getOrderListAsync = createAsyncThunk(
  'order/list',
  async (data : any) => {
    const response = await getOrderList(data);
    return response.data;
  }
);
export const getOrderDetailAsync = createAsyncThunk(
  'order/detail',
  async (index: string) => {
    const response = await getOrderDetail(index);
    return response.data;
  }
);

export const createOrderAsync = createAsyncThunk(
  'order/create',
  async (data: OrderDetailInterface) => {
    const response = await createOrder(data);
    return response.data;
  }
);

export const deleteOrderAsync = createAsyncThunk(
  'order/delete',
  async (index: string) => {
    const response = await deleteOrder(index);
    return response.data;
  }
);

export const updateOrderAsync = createAsyncThunk(
  'order/update',
  async (data: {
    index: string, data: OrderDetailInterface
  }) => {
    const response = await updateOrder(data.index, data.data);
    return response.data;
  }
);

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getOrderListAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getOrderListAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.orders = action.payload.data;
        state.totalRecord = action.payload.total

      })
      .addCase(getOrderListAsync.rejected, (state) => {
        state.status = 'failed';
      })
      // GET DETAIL
      .addCase(getOrderDetailAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getOrderDetailAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.detail = action.payload.data;
      })
      .addCase(getOrderDetailAsync.rejected, (state) => {
        state.status = 'failed';
      })
      // CREATE
      .addCase(createOrderAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createOrderAsync.fulfilled, (state, action) => {
        state.status = 'created';
        state.detail = action.payload;
      })
      .addCase(createOrderAsync.rejected, (state) => {
        state.status = 'failed';
      })
      // UPDATE
      .addCase(updateOrderAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateOrderAsync.fulfilled, (state, action) => {
        state.status = 'updated';
        state.detail = action.payload;
      })
      .addCase(updateOrderAsync.rejected, (state) => {
        state.status = 'failed';
      })
      // DELETE
      .addCase(deleteOrderAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteOrderAsync.fulfilled, (state, action) => {
        state.status = 'deleted';
        state.detail = action.payload;
      })
      .addCase(deleteOrderAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {  } = orderSlice.actions;
export const OrderState = (state: RootState) => state.order;
export default orderSlice.reducer;
