import { Route } from "react-router-dom";
import ProductCreate from "./pages/ProductCreate";
import ProductList from "./pages/ProductList";
import ProductQtyUpdate from "./pages/ProductQtyUpdate";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "product"; 
const ProductRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<ProductList />} key={`${MODULE_ROUTE}_index`} />,
    <Route path={`${MODULE_ROUTE}/create`} element={<ProductCreate />}  key={`${MODULE_ROUTE}_create`}  />,
    <Route path={`${MODULE_ROUTE}/update/:id`} element={<ProductCreate />} key={`${MODULE_ROUTE}_edit`} />,
    <Route path={`${MODULE_ROUTE}/update-qty/:id`} element={<ProductQtyUpdate />} key={`${MODULE_ROUTE}_qty_update`} />,
  ];

export default ProductRoute;