import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import appReducer from './appSlice';
import categoryReducer from "../modules/category/CategorySlice";
import productReducer from "../modules/product/ProductSlice";
import brandReducer from "../modules/brand/BrandSlice";
import userReducer from "../modules/user/UserSlice";
import orderReducer from "../modules/order/OrderSlice";
import merchantReducer from "../modules/merchant/MerchantSlice";
import contentReducer from "../modules/content/ContentSlice";
/* import store */
export const store = configureStore({
  reducer: {
    app: appReducer,
category : categoryReducer,
product : productReducer,
brand : brandReducer,
user : userReducer,
order : orderReducer,
merchant : merchantReducer,
content : contentReducer,
// {addStore}
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
