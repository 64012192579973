import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../stores';
import { CategoryDetailInterface } from './models/CategoryDetailInterface';
import { createCategory, deleteCategory, getCategoryDetail, getCategoryList, updateCategory } from './CategoryAPI';

export interface CategoryStateInterface {
  categorys: CategoryDetailInterface[];
  favourites: CategoryDetailInterface[];
  totalRecord: number;
  status: 'idle' | 'loading' | 'failed' | 'created' | 'updated' | 'deleted';
  detail: CategoryDetailInterface,
}

const initialState: CategoryStateInterface = {
  categorys: [],
  favourites: [],
  totalRecord: 0,
  status: 'idle',
  detail: {
    id: "",
    name: "",
    image: "",
    status: ""
  }
};

export const getCategoryListAsync = createAsyncThunk(
  'category/list',
  async (data : any) => {
    const response = await getCategoryList(data);
    return response.data;
  }
);
export const getCategoryDetailAsync = createAsyncThunk(
  'category/detail',
  async (index: string) => {
    const response = await getCategoryDetail(index);
    return response.data;
  }
);

export const createCategoryAsync = createAsyncThunk(
  'category/create',
  async (data: CategoryDetailInterface) => {
    const response = await createCategory(data);
    return response.data;
  }
);

export const deleteCategoryAsync = createAsyncThunk(
  'category/delete',
  async (index: string) => {
    const response = await deleteCategory(index);
    return response.data;
  }
);

export const updateCategoryAsync = createAsyncThunk(
  'category/update',
  async (data: {
    index: string, data: CategoryDetailInterface
  }) => {
    const response = await updateCategory(data.index, data.data);
    return response.data;
  }
);

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getCategoryListAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCategoryListAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.categorys = action.payload.data;
        state.totalRecord = action.payload.total

      })
      .addCase(getCategoryListAsync.rejected, (state) => {
        state.status = 'failed';
      })
      // GET DETAIL
      .addCase(getCategoryDetailAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCategoryDetailAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.detail = action.payload;
      })
      .addCase(getCategoryDetailAsync.rejected, (state) => {
        state.status = 'failed';
      })
      // CREATE
      .addCase(createCategoryAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createCategoryAsync.fulfilled, (state, action) => {
        state.status = 'created';
        state.detail = action.payload;
      })
      .addCase(createCategoryAsync.rejected, (state) => {
        state.status = 'failed';
      })
      // UPDATE
      .addCase(updateCategoryAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateCategoryAsync.fulfilled, (state, action) => {
        state.status = 'updated';
        state.detail = action.payload;
      })
      .addCase(updateCategoryAsync.rejected, (state) => {
        state.status = 'failed';
      })
      // DELETE
      .addCase(deleteCategoryAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteCategoryAsync.fulfilled, (state, action) => {
        state.status = 'deleted';
        state.detail = action.payload;
      })
      .addCase(deleteCategoryAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {  } = categorySlice.actions;
export const CategoryState = (state: RootState) => state.category;
export default categorySlice.reducer;
