import { useEffect, useState } from "react"
import { Formik } from "formik"
import AxiosInstance from "../utils/AxiosInstance"
import { useNavigate } from "react-router-dom"
import { getProfile, setProfile } from "../stores/appSlice"
import { useAppDispatch } from "../stores/hooks"
import { store } from "../stores"
import "react-toastify/dist/ReactToastify.css"
import { ToastContainer, toast } from "react-toastify"

const Signin = () => {
  const navigate = useNavigate()
  const dispatchApp = useAppDispatch()
  const token = localStorage.getItem("token")
  const [loginSuccess, setLoginSuccess] = useState(token ? true : false)

  useEffect(() => {
    if (loginSuccess) {
      if(localStorage.getItem("update_password") == '1'){
        navigate("/change-password")
      }else{
        navigate("/home")
      }
    }
  }, [loginSuccess])

  const onsubmit = async (values: any) => {
    if (!values.email || !values.password) {
      toast.error("Please enter valid email and password.", {
        position: toast.POSITION.TOP_RIGHT,
      })
      return;
    }
    const data = {
      email: values.email,
      password: values.password,
    }
    const res = await AxiosInstance.post("signin", data)
    
    if (res?.status === 200) {
      if (res?.data?.data?.user?.status == "Active") {
        
        toast.success("Login Successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        })
        setLoginSuccess(true)
        localStorage.setItem("token", res?.data?.data?.token)
        localStorage.setItem("update_password", res?.data?.data?.user?.update_password)
        // dispatchApp(getProfile());
        dispatchApp(setProfile(res?.data?.data?.user))
        if(res?.data?.data?.user?.update_password === "1"){
          navigate("/change-password")
        }else{
          navigate("/home")
        }

      }else{
        toast.warning(`Dear ${res?.data?.data?.user?.role} your account is not Active. Please Contact to Admin.`, {
          position: toast.POSITION.TOP_RIGHT,
        })
      }

    } else {
      toast.error("Please enter valid login credentials.", {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }

  return (
    <>
      <ToastContainer />
      <div className="auth-wrapper">
        <div className="auth-content">
          <div className="auth-bg">
            <span className="r"></span>
            <span className="r s"></span>
            <span className="r s"></span>
            <span className="r"></span>
          </div>

          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            enableReinitialize={true}
            validate={(values: { email: string; password: string }) => {
              const errors: { email?: string; password?: string } = {}

              // if (!values.email) {
              //   errors.email = "Required"
              // }

              // if (!values.password) {
              //   errors.password = "Required"
              // }
              return errors
            }}
            onSubmit={onsubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="card">
                  <div className="card-body text-center">
                    <div className="mb-4">
                      <img className="login-logo" src="/images/app-logo.png" />
                    </div>
                    <h3 className="mb-4">Login</h3>
                    <div className="input-group mb-3">
                      <input
                        className={
                          errors.email && touched.email && errors.email
                            ? "form-control error"
                            : "form-control"
                        }
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        placeholder="Email"
                        autoFocus={true}
                      />
                    </div>
                    <div className="input-group mb-4">
                      <input
                        className={
                          errors.password && touched.password && errors.password
                            ? "form-control error"
                            : "form-control"
                        }
                        type="password"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        placeholder="Password"
                      />
                    </div>
                    {/* <div className="form-group text-left">
                    <div className="checkbox checkbox-fill d-inline">
                      <input
                        type="checkbox"
                        name="checkbox-fill-1"
                        id="checkbox-fill-a1"
                      />
                      <label htmlFor="checkbox-fill-a1" className="cr">
                        {" "}
                        Save Details
                      </label>
                    </div>
                  </div> */}
                    <button
                      type="submit"
                      className="btn btn-primary shadow-2 mb-4 mt-4"
                    >
                      Login
                    </button>
                    {/* <p className="mb-2 text-muted">
                    Forgot password?{" "}
                    <a href="auth-reset-password.html">Reset</a>
                  </p> */}
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}

export default Signin
