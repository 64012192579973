import { Link, useNavigate, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../stores/hooks"
import { getBrandDetailAsync, BrandState } from "../BrandSlice"
import { Formik } from "formik"
import { BrandDetailInterface } from "../models/BrandDetailInterface"
import ModuleConfig from "../config/ModuleConfig"
import FormField from "../../common/FormField"
import { useEffect } from "react"
import { createBrand, updateBrand } from "../BrandAPI"
import "react-toastify/dist/ReactToastify.css"
import { ToastContainer, toast } from "react-toastify"
import BackBtn from "../../common/BackBtn"
import { appState } from "../../../stores/appSlice"

/**
 * Branding Favourites list page
 */
const BrandCreate = () => {
  const dispatch = useAppDispatch()
  const { profile } = useAppSelector(appState);
  const { status, detail } = useAppSelector(BrandState)
  const params = useParams()
  const navigator = useNavigate()

  useEffect(() => {
    if (params.id) {
      dispatch(getBrandDetailAsync(params.id))
    }
  }, [params, dispatch])

  const handleSubmit = async (
    values: BrandDetailInterface,
    formMethod: any
  ) => {
    if (params.id) {
      const res: any = await updateBrand(params.id, values)
      if (res.errors && res.message) {
        const errors: any = {}
        const errorKeys = Object.keys(res.errors)
        for (var i = 0; i < errorKeys.length; i++) {
          errors[errorKeys[i]] = res.errors[errorKeys[i]][0]
        }
        formMethod.setErrors(errors)
      } else if (res.data.status == "200") {
        toast.success("Brand updated!", {
          position: toast.POSITION.TOP_RIGHT,
        })
        setTimeout(() => {
          navigator("/brand")
        }, 1000)
      }
    } else {
      const res: any = await createBrand(values)
      if (res.errors && res.message) {
        const errors: any = {}
        const errorKeys = Object.keys(res.errors)
        for (var i = 0; i < errorKeys.length; i++) {
          errors[errorKeys[i]] = res.errors[errorKeys[i]][0]
        }
        formMethod.setErrors(errors)
      } else if (res.data.status == "200") {
        toast.success("Brand created!", {
          position: toast.POSITION.TOP_RIGHT,
        })
        setTimeout(() => {
          navigator("/brand")
        }, 1000)
      }
    }
  }

  return (
    <>
      <div className="page-header">
        <ToastContainer />
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-11">
              <div className="page-header-title">
                <h5 className="m-b-10">Brand</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/brand">Brands</Link>
                </li>
                <li className="breadcrumb-item">
                  <a>{params.id ? "Edit " : "Add "}Brand</a>
                </li>
              </ul>
            </div>
            <BackBtn />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>{params.id ? "Edit " : "Add "}Brand</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <Formik
                    initialValues={
                      detail[ModuleConfig.primaryKey] === params.id
                        ? detail
                        : ModuleConfig.formDefaultValue
                    }
                    validate={(values) => {
                      const errors: any = {}
                      const fields = Object.keys(ModuleConfig.formFields)
                      for (let index = 0; index < fields.length; index++) {
                        if (
                          !values[fields[index]] &&
                          ModuleConfig.formFields[fields[index]].isRequired
                        ) {
                          errors[fields[index]] = "Required"
                        }
                      }

                      return errors
                    }}
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          {Object.keys(ModuleConfig.formFields).map(
                            (field: string, index: number) => {
                              const fieldDetails =
                                ModuleConfig.formFields[field]
                              return fieldDetails?.hideFor != profile?.role ? (
                                <div
                                  key={`field_${index}`}
                                  className={fieldDetails.colWidth}
                                >
                                  <FormField
                                    name={field}
                                    fieldDetails={fieldDetails}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    setFieldValue={setFieldValue}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                  />
                                </div>
                              ) : null
                            }
                          )}
                        </div>
                        <hr />
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={status === "loading"}
                        >
                          Submit
                        </button>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default BrandCreate
