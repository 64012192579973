import CkEditor from "./CkEditor"
import DatePicker from "./DatePicker"
import FileInput from "./FileInput"
import InputSelect from "./InputSelect"
import MyDropzone from "./MyDropzone"
import TextAreaInput from "./TextAreaInput"
import TextInput from "./TextInput"
import ArrayType from "./ArrayType";

const FormField = (props: {
    name: string,
    readOnly?: boolean,
    fieldDetails: any,
    isGrouped?: boolean,
    values: any,
    errors: any,
    touched: any,
    handleChange: any,
    handleBlur: any,
    setFieldValue?: any,
    addMoreFields?: any;
    index?: any;
    ref? : any;
}) => {

    const getField = () => {

	if (props.fieldDetails.inputType === "array") {
      	    return <ArrayType {...props} />;
    	}
        if (props.fieldDetails.inputType === "select") {
            return <InputSelect {...props} />;
        }
        if (props.fieldDetails.inputType === "textarea") {
            return <TextAreaInput {...props} />;
        }
        if (props.fieldDetails.inputType === "ckeditor") {
            return <CkEditor {...props} />;
        }
        if (props.fieldDetails.inputType === "dropzone") {
            return <MyDropzone {...props} />;
        }
        if (props.fieldDetails.inputType === "datepicker") {
            return <DatePicker {...props} />;
        }
        if (props.fieldDetails.inputType === "file") {
            return <FileInput {...props} />;
        }
        return <TextInput type={props.fieldDetails.type} {...props} />
    }
    return (
        <div className="form-group mb-4">
            <label className="input-label" htmlFor={props.name}>{props.fieldDetails.label}</label>
            {getField()}
            {typeof props.errors[props.name] === "string" ?
                <div className="error">{props.errors[props.name]}</div>
                : null}
        </div>
    )
}
export default FormField;