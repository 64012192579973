import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import AxiosInstance from "../utils/AxiosInstance";
import { toast } from "react-toastify"


export interface AppStateInterface {
  isMobileMenuOpen: boolean;
  profile: any;
  isUserLoggedIn: boolean;
}

const initialState: AppStateInterface = {
  isMobileMenuOpen: false,
  profile: {},
  isUserLoggedIn: false,
};

export const appSlice = createSlice({
  name: "app",
  initialState,

  reducers: {
    changeMobileMenuState: (state) => {
      state.isMobileMenuOpen = !state.isMobileMenuOpen;
    },
    setProfile: (state, action: PayloadAction<[]>) => {
      state.profile = action.payload;
    },
    resetProfile: (state, action: PayloadAction<[]>) => {
      state = initialState;
    },
  },
});

export const getProfile = () => async (disptach: Function) => {

  const res: any = await AxiosInstance.get("profile");
  disptach(setProfile(res?.data?.data));

  if (res?.status === 200) {
    if (res?.data?.data?.status != "Active") {
      const res1: any = await AxiosInstance.get("signout")
      if (res1?.status === 200) {
        localStorage.clear();
        window.location.href = "/signin";
        window.location.reload();
      }
    }
  }

};

export const { changeMobileMenuState, setProfile, resetProfile } =
  appSlice.actions;

export const appState = (state: any) => {
  return state.app;
};

export default appSlice.reducer;
