import AxiosInstance from "../../utils/AxiosInstance";
import { OrderDetailInterface } from "./models/OrderDetailInterface";

const API_ROUTE = "orders";
export const getOrderList = async (params : any) => {
  const prm = new URLSearchParams(params).toString()
  return await AxiosInstance.get(`/${API_ROUTE}?${prm}`);
};

export const getOrderDetail = async (index: string) => {
  return await AxiosInstance.get(`/${API_ROUTE}/${index}`);
};

export const createOrder = async (data: OrderDetailInterface) => {
  const formData = new FormData();
  const keys = Object.keys(data);
  for (var i = 0; i < keys.length; i++) {
    formData.append(keys[i], data[keys[i]]);
  }

  return await AxiosInstance.post(`/${API_ROUTE}`, formData);
};

export const deleteOrder = async (index: string) => {
  return await AxiosInstance.delete(`/${API_ROUTE}/${index}`);
};
export const updateOrder = async (index: string, data: OrderDetailInterface) => {
  const formData = new FormData();
  const keys = Object.keys(data);
  for (var i = 0; i < keys.length; i++) {
    formData.append(keys[i], data[keys[i]]);
  }

  return await AxiosInstance.post(`/${API_ROUTE}/${index}`, formData);
};
