import { useEffect, useState } from "react";
import { Alert, Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../stores/hooks";
import {
  getProductListAsync,
  ProductState,
  setCreatProduct,
} from "../ProductSlice";
import {
  FaAd,
  FaCartPlus,
  FaEdit,
  FaPushed,
  FaTrash,
  FaTrashAlt,
} from "react-icons/fa";
import { ProductDetailInterface } from "../models/ProductDetailInterface";
import ModuleConfig from "./../config/ModuleConfig";
import CustomPagination from "../../../utils/CustomPagination";
import { deleteProduct } from "../ProductAPI";
import { getPropByString } from "./../../../utils/CommonFunction";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import BackBtn from "../../common/BackBtn";
import AxiosInstance from "../../../utils/AxiosInstance";
/**
 * Producting list page
 */
const ProductList = () => {
  const { products, status, totalRecord } = useAppSelector(ProductState);
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [statusTerm, setStatusTerm] = useState<any>("");
  const [giftType, setGiftType] = useState<any>("");
  const [order, setOrder] = useState("ASC");
  const [deleteItem, setDeleteItem] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("ASC");
  const [brand, setBrand] = useState("");
  const [brandList, setBrandList] = useState<any>(null);
  const [merchant, setMerchant] = useState("");

  const [merchantList, setMerchantList] = useState<any>(null);
  useEffect(() => {
    getPageData();
  }, [dispatch, page, search, statusTerm, giftType, sortBy, sortOrder, brand, merchant]);

  const getPageData = () => {
    const filter = {
      page: page,
      search: search,
      brand: brand,
      merchant: merchant,
      gift_type: giftType,
      status: statusTerm,
      orderBy: sortBy,
      order: sortOrder,
    };
    dispatch(getProductListAsync(filter));
  };

  useEffect(() => {
    if (products) {
      setData(products);
    }
  }, [products]);

  const removeProduct = async () => {
    const res = await deleteProduct(deleteItem);
    if (res.data.status == 200) {
      toast.success("Product deleted!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setDeleteItem("");
    getPageData();
  };

  const updateSortOrder = (col: string) => {
    // if(col === "user.name" || col === "merchant.name"){
    //   return false
    // }
    if (col === sortBy) {
      setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");
    } else {
      setSortBy(col);
      setSortOrder("ASC");
    }
  };

  const sorting = (col: string) => {
    if (order === "ASC") {
      const sorted = [...data].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setData(sorted);
      setOrder("DESC");
    }
    if (order === "DESC") {
      const sorted = [...data].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setData(sorted);
      setOrder("ASC");
    }
  };

  const handleClear = () => {
    setSearch("");
    setStatusTerm("");
    setGiftType("");
    setMerchant("");
    setBrand("");
  };

  useEffect(() => {
    if (!brandList) {
      getBrandList()
    }
    if (!merchantList) {
      getMerchantList()
    }
  }, [])

  const getBrandList = async () => {
    const res = await AxiosInstance.get("/brands");
    setBrandList(res?.data?.data)
  }

  const getMerchantList = async () => {
    const res = await AxiosInstance.get("/merchants");
    setMerchantList(res.data.data)
  }

  return (
    <>
      <div className="page-header">
        <ToastContainer />
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-11">
              <div className="page-header-title">
                <h5 className="m-b-10">Product</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <a>Products</a>
                </li>
              </ul>
            </div>
            <BackBtn />
          </div>
        </div>
      </div>

      <div className="main-body">
        <div className="page-wrapper">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header">
                  <Row>
                    <Col md={10}>
                      <h5>Products</h5>
                    </Col>
                    <Col md={2}>
                      <Link
                        className="btn btn-primary"
                        to="/product/create"
                        onClick={() => dispatch(setCreatProduct({}))}
                      >
                        Add Product
                      </Link>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={3}>
                      <input
                        type="text"
                        placeholder="Search Product Name"
                        className="form-control"
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                        value={search}
                      />
                    </Col>
                    <Col md={2}>
                      <select
                        placeholder="Select Status"
                        className="form-control form-select "
                        value={statusTerm}
                        onChange={(e) => {
                          setStatusTerm(e.target.value);
                        }}
                      >
                        <option value="">Select Status</option>
                        <option value="Pending">Pending</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </select>
                    </Col>
                    <Col md={2}>
                      <select
                        placeholder="Select Status"
                        className="form-control form-select "
                        value={giftType}
                        onChange={(e) => {
                          setGiftType(e.target.value);
                        }}
                      >
                        <option value="">Select Order Type</option>
                        <option value="Delivery">Delivery</option>
                        <option value="eGift">eGift</option>
                      </select>
                    </Col>
                    <Col md={2}>
                      <select
                        placeholder="Select Brand"
                        className="form-control form-select "
                        value={brand}
                        onChange={(e) => {
                          setBrand(e.target.value);
                        }}
                      >
                        <option value="">Select Brand</option>
                        {brandList?.map((item: any, index: number) => {
                          return (
                            <option value={item?.id}>{item?.name}</option>
                          );
                        })}

                      </select>
                    </Col>
                    <Col md={2}>
                      <select
                        placeholder="Select Merchant"
                        className="form-control form-select "
                        value={merchant}
                        onChange={(e) => {
                          setMerchant(e.target.value);
                        }}
                      >
                        <option value="">Select Merchant</option>
                        {merchantList?.map((item: any, index: number) => {
                          return (
                            <option value={item.id}>{item?.name}</option>
                          );
                        })}
                      </select>
                    </Col>
                    <Col md={1}>
                      <button
                        type="button"
                        className=" btn btn-warning"
                        onClick={() => {
                          handleClear();
                        }}
                      >
                        Reset
                      </button>
                    </Col>
                  </Row>
                </div>

                <div className="card-block table-border-style">


                  {status === "loading" ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : status === "failed" ? (
                    <Alert key={"danger"} variant={"danger"}>
                      Somthing went wrong please try again
                    </Alert>
                  ) : (
                    <>
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              {ModuleConfig.ModuleConfig.listColumnOrder.map(
                                (column: string, index: number) => {
                                  return (
                                    <th
                                      key={`head_${index}`}
                                      onClick={() => {
                                        updateSortOrder(column);
                                      }}
                                    >
                                      {
                                        ModuleConfig.ModuleConfig.formFields[
                                          column
                                        ].label
                                      }
                                    </th>
                                  );
                                }
                              )}
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.length >= 1 ? (
                              data.map(
                                (
                                  product: ProductDetailInterface,
                                  index: number
                                ) => {
                                  return (
                                    <tr key={`table_row_${index}`}>
                                      <>
                                        {ModuleConfig.ModuleConfig.listColumnOrder.map(
                                          (
                                            column: string,
                                            colIndex: number
                                          ) => {
                                            return (
                                              <td
                                                key={`table_row_${index}_${colIndex}`}
                                              >
                                                <>
                                                  {/* column - order Type */}
                                                  {colIndex == 3 ? (
                                                    <span
                                                      className={
                                                        "label " +
                                                        (getPropByString(
                                                          product,
                                                          column
                                                        ) == "eGift"
                                                          ? "label-eGift"
                                                          : "label-delivery")
                                                      }
                                                    >
                                                      {getPropByString(
                                                        product,
                                                        column
                                                      )}
                                                    </span>
                                                  ) : (
                                                    <>
                                                      {/* column - amount */}
                                                      {colIndex == 1 ? (
                                                        <b>
                                                          {" "}
                                                          ${" "}
                                                          {Number(
                                                            getPropByString(
                                                              product,
                                                              column
                                                            )
                                                          ).toFixed(2)}
                                                        </b>
                                                      ) : (
                                                        getPropByString(
                                                          product,
                                                          column
                                                        )
                                                      )}{" "}
                                                    </>
                                                  )}
                                                </>

                                                {/* {product[column]} */}
                                              </td>
                                            );
                                          }
                                        )}

                                        <td>
                                          <Link
                                            className="me-2 btn btn-icon btn-primary"
                                            to={`/product/update-qty/${product[
                                              ModuleConfig.ModuleConfig
                                                .primaryKey
                                            ]
                                              }`}
                                            title="Manage Stock"
                                          >
                                            <FaCartPlus />
                                          </Link>

                                          <Link
                                            className="me-2 btn btn-icon btn-success"
                                            to={`/product/update/${product[
                                              ModuleConfig.ModuleConfig
                                                .primaryKey
                                            ]
                                              }`}
                                            title="Edit"
                                          >
                                            <FaEdit />
                                          </Link>

                                          <a
                                            className="btn btn-icon btn-danger"
                                            onClick={() =>
                                              setDeleteItem(product?.id || "")
                                            }
                                            title="Delete"
                                          >
                                            <i className="feather icon-trash-2"></i>
                                          </a>
                                        </td>
                                      </>
                                    </tr>
                                  );
                                }
                              )
                            ) : (
                              <tr>
                                <td
                                  colSpan={
                                    ModuleConfig.ModuleConfig.listColumnOrder
                                      .length + 1
                                  }
                                >
                                  No Record.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <CustomPagination
                        curretnPage={page}
                        total={totalRecord}
                        limit={10}
                        setPage={setPage}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {deleteItem ? (
        <Modal show={true} onHide={() => setDeleteItem("")}>
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete Product?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setDeleteItem("")}>
              Close
            </Button>
            <Button
              className="btn-danger"
              variant="primary"
              onClick={removeProduct}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  );
};
export default ProductList;
