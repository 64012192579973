import { useEffect, useState } from "react"
import AxiosInstance from "../utils/AxiosInstance"
import { Link } from "react-router-dom"
import { useAppSelector } from "../stores/hooks"
import { appState } from "./../stores/appSlice"
import { useNavigate } from "react-router-dom";

const Home = () => {
  
  const [homeData, setHomeData] = useState<any>({})
  const { profile } = useAppSelector(appState)
  const  navigate  = useNavigate();
  if(localStorage.getItem("update_password") == '1'){
    navigate("/change-password")
  }

  const getHomeData = async () => {
    const res = await AxiosInstance.get("home")
    if (res.status === 200) {
      setHomeData(res?.data?.data)
    }
  }

  useEffect(() => {
    getHomeData()
  }, [])

  const formatter = new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });


  return (
    <div className="pcoded-wrapper">
      <div className="pcoded-content">
        <div className="pcoded-inner-content">
          <div className="main-body">
            <div className="page-wrapper">
              {/* [ Main Content ] start  */}
              <div className="row">
                {/*[ user section ] start */}
                {profile?.role === "Admin" ? (
                  <div className="col-md-6 col-xl-4">
                    <div className="card daily-sales">
                      <Link to="/user" className="card-block">
                        <h6 className="mb-4">Users</h6>
                        <div className="row d-flex align-items-center">
                          <div className="col-9">
                            <h3 className="f-w-600 text-c-yellow d-flex align-items-center m-b-0">
                              <i className="feather icon-users text-c-yellow f-30 m-r-10"></i>
                              {homeData.userCount}
                            </h3>
                          </div>
                        </div>
                        <div
                          className="progress m-t-30"
                          style={{ height: "7px" }}
                        >
                          <div
                            className="progress-bar progress-c-theme"
                            role="progressbar"
                            style={{ width: "100%" }}
                            aria-valuenow={50}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          ></div>
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : null}
                {/*[ user section ] end */}

                {/*[ Merchants section ] starts */}
                {profile?.role === "Admin" ? (
                  <div className="col-md-6 col-xl-4">
                    <div className="card Monthly-sales">
                      <Link to="/merchant" className="card-block">
                        <h6 className="mb-4">Merchants</h6>
                        <div className="row d-flex align-items-center">
                          <div className="col-9">
                            <h3 className="f-w-600 text-c-green d-flex align-items-center  m-b-0">
                              <i className="feather icon-award text-c-green f-30 m-r-10"></i>
                              {homeData.merchantCount}
                            </h3>
                          </div>
                          <div className="col-3 text-right"></div>
                        </div>
                        <div
                          className="progress m-t-30"
                          style={{ height: "7px" }}
                        >
                          <div
                            className="progress-bar progress-c-theme"
                            role="progressbar"
                            style={{ width: "100%" }}
                            aria-valuenow={35}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          ></div>
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : null}
                {/*[ Merchants section ] end */}

                {/*[ brands section ] starts */}
                {profile?.role === "Admin" ? (
                  <div className="col-md-12 col-xl-4">
                    <div className="card yearly-sales">
                      <Link to="/brand" className="card-block">
                        <h6 className="mb-4">Brands</h6>
                        <div className="row d-flex align-items-center">
                          <div className="col-9">
                            <h3 className="f-w-600 text-c-purple d-flex align-items-center  m-b-0">
                              <i className="feather icon-star text-c-purple f-30 m-r-10"></i>
                              {homeData.brandCount}
                            </h3>
                          </div>
                          <div className="col-3 text-right"></div>
                        </div>
                        <div
                          className="progress m-t-30"
                          style={{ height: "7px" }}
                        >
                          <div
                            className="progress-bar progress-c-theme"
                            role="progressbar"
                            style={{ width: "1000%" }}
                            aria-valuenow={70}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          ></div>
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : null}
                {/*[ brands section ] end */}

                {/*[ product section ] starts */}
                <div className="col-md-12 col-xl-4">
                  <div className="card yearly-sales">
                    <Link to="/product" className="card-block">
                      <h6 className="mb-4">Products</h6>
                      <div className="row d-flex align-items-center">
                        <div className="col-9">
                          <h3 className="f-w-600 text-c-red d-flex align-items-center  m-b-0">
                            <i className="feather icon-package text-c-red f-30 m-r-10"></i>
                            {homeData.productCount}
                          </h3>
                        </div>
                        <div className="col-3 text-right">
                          {/* <p className="m-b-0">80%</p> */}
                        </div>
                      </div>
                      <div
                        className="progress m-t-30"
                        style={{ height: "7px" }}
                      >
                        <div
                          className="progress-bar progress-c-theme"
                          role="progressbar"
                          style={{ width: "1000%" }}
                          aria-valuenow={70}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        ></div>
                      </div>
                    </Link>
                  </div>
                </div>
                {/*[ product section ] end */}

                {/*[ order section ] starts */}
                <div className="col-md-12 col-xl-4">
                  <div className="card yearly-sales">
                    <Link to="/order" className="card-block">
                      <h6 className="mb-4">Orders</h6>
                      <div className="row d-flex align-items-center">
                        <div className="col-9">
                          <h3 className="f-w-600 text-c-blue d-flex align-items-center  m-b-0">
                            <i className="feather icon-shopping-cart text-c-blue f-30 m-r-10"></i>
                            {homeData.orderCount}
                          </h3>
                        </div>
                        <div className="col-3 text-right">
                          {/* <p className="m-b-0">80%</p> */}
                        </div>
                      </div>
                      <div
                        className="progress m-t-30"
                        style={{ height: "7px" }}
                      >
                        <div
                          className="progress-bar progress-c-theme"
                          role="progressbar"
                          style={{ width: "1000%" }}
                          aria-valuenow={70}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        ></div>
                      </div>
                    </Link>
                  </div>
                </div>
                {/*[ order section ] end */}

                {/*[ Recent Orders ] start */}
                <div className="col-xl-12 col-md-12">
                  <div className="card Recent-Users">
                    <div className="card-header">
                      <h5>Recent Orders</h5>
                    </div>
                    <div className="card-block px-0 py-3">
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <thead>
                            <th>Order Number</th>
                            <th>Price</th>
                            <th>Order Type</th>
                            <th>Status</th>
                            <th>Order At</th>
                          </thead>
                          <tbody>
                            {homeData?.recentOrders?.map(
                              (order: any, index: any) => {
                                return (
                                  <tr className="unread">
                                    <td>
                                      <h6 className="mb-1">
                                        <Link to={`/order/detail/${order?.id}`}>
                                          {order.order_number}
                                        </Link>
                                      </h6>
                                    </td>
                                    <td>
                                      <h6>
                                        <b>${order?.total_price}</b>
                                      </h6>
                                    </td>

                                    <td>
                                      <h6 className="mb-1">
                                        <span
                                          className={
                                            order.order_type == "eGift"
                                              ? "label label-eGift"
                                              : "label label-delivery"
                                          }
                                        >
                                          {" "}
                                          {order.order_type}{" "}
                                        </span>
                                      </h6>
                                    </td>
                                    <td>
                                      <h6 className="mb-1">
                                        <span
                                          className={
                                            order?.status == "Accepted"
                                            ? "label label-success"
                                            : order?.status === "Delivered"
                                            ? "label label-info"
                                            : order?.status === "Pending"
                                            ? "label label-dark"
                                            : "label label-danger"
                                          }
                                        >
                                          {order.status}
                                        </span>
                                      </h6>
                                    </td>
                                    <td>
                                      <h6 className="text-muted">
                                        {/* <i className="fas fa-circle text-c-green f-10 m-r-15"></i> */}
                                        {formatter
                                          .format(Date.parse(order.created_at))
                                          .toUpperCase()}
                                      </h6>
                                    </td>
                                  </tr>
                                )
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                {/*[ Recent Orders ] end */}
              </div>
              {/* [ Main Content ] end  */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
