import AxiosInstance from "../../utils/AxiosInstance";
import { ProductDetailInterface } from "./models/ProductDetailInterface";
import { ProductQtyInterface } from "./models/ProductQtyInterface";

const API_ROUTE = "products";
export const getProductList = async (params : any) => {
  const prm = new URLSearchParams(params).toString()
  return await AxiosInstance.get(`/${API_ROUTE}?${prm}`);
};

export const getProductDetail = async (index: string) => {
  return await AxiosInstance.get(`/${API_ROUTE}/${index}`);
};

export const createProduct = async (data: ProductDetailInterface) => {
  const formData = new FormData();
  const keys = Object.keys(data);
  for (var i = 0; i < keys.length; i++) {
    if(keys[i] === "product_option_detail"  ){
      formData.append(keys[i], JSON.stringify(data[keys[i]]));
    }else{
      formData.append(keys[i], data[keys[i]]);
    }
  }

  return await AxiosInstance.post(`/${API_ROUTE}`, formData);
};

export const deleteProduct = async (index: string) => {
  return await AxiosInstance.delete(`/${API_ROUTE}/${index}`);
};
export const updateProduct = async (index: string, data: ProductDetailInterface) => {
  const formData = new FormData();
  const keys = Object.keys(data);
  for (var i = 0; i < keys.length; i++) {
    if(keys[i] === "product_option_detail" || keys[i] === "product_image" || keys[i] === "category_detail" || keys[i] === "brand_detail" || keys[i] === "merchant_detail"  ){
      formData.append(keys[i], JSON.stringify(data[keys[i]]));
    }else{
      formData.append(keys[i], data[keys[i]]);
    }
  }

  return await AxiosInstance.post(`/${API_ROUTE}/${index}`, formData);
};

export const productQtyUpdate = async (index: string, data: ProductQtyInterface) => {
  const formData = new FormData();
  const keys = Object.keys(data);
  for (var i = 0; i < keys.length; i++) {
    if(keys[i] === "product_option_detail" || keys[i] === "product_image" || keys[i] === "category_detail" || keys[i] === "brand_detail" || keys[i] === "merchant_detail"  ){
      formData.append(keys[i], JSON.stringify(data[keys[i]]));
    }else{
      formData.append(keys[i], data[keys[i]]);
    }
  }

  return await AxiosInstance.post(`/${API_ROUTE}/${index}`, formData);
};

export const uploadProductImage = async (file:any) => {
  const formData = new FormData();
  // const keys = Object.keys(data);
  // for (var i = 0; i < keys.length; i++) {
    formData.append("file", file);
  // }

  return await AxiosInstance.post(`/image-upload`, formData);
};

export const deleteProductImage = async (id:any) => {
  const formData = new FormData();
  // const keys = Object.keys(data);
  // for (var i = 0; i < keys.length; i++) {
    formData.append("id", id);
  // }

  return await AxiosInstance.post(`/image-delete`, formData);
};