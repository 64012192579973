import { Route } from "react-router-dom";
import MerchantCreate from "./pages/MerchantCreate";
import MerchantList from "./pages/MerchantList";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "merchant"; 
const MerchantRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<MerchantList />} key={`${MODULE_ROUTE}_index`} />,
    <Route path={`${MODULE_ROUTE}/create`} element={<MerchantCreate />}  key={`${MODULE_ROUTE}_create`}  />,
    <Route path={`${MODULE_ROUTE}/update/:id`} element={<MerchantCreate />} key={`${MODULE_ROUTE}_edit`} />,
  ];

export default MerchantRoute;