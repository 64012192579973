import { useEffect, useState } from "react";
import { Alert, Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../stores/hooks";
import { getOrderListAsync, OrderState } from "../OrderSlice";
import { FaEdit, FaTrash, FaTrashAlt } from "react-icons/fa";
import { OrderDetailInterface } from "../models/OrderDetailInterface";
import ModuleConfig from "./../config/ModuleConfig";
import CustomPagination from "../../../utils/CustomPagination";
import { deleteOrder } from "../OrderAPI";
import { getPropByString } from "../../../utils/CommonFunction";
import BackBtn from "../../common/BackBtn";
/**
 * Ordering list page
 */
const OrderList = () => {
  const { orders, status, totalRecord } = useAppSelector(OrderState);
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [statusTerm, setStatusTerm] = useState<any>("");
  const [orderType, setOrderType] = useState<any>("");
  const [order, setOrder] = useState("ASC");
  const [deleteItem, setDeleteItem] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("ASC");

  useEffect(() => {
    getPageData();
  }, [dispatch, page, search, statusTerm, orderType, sortBy, sortOrder]);

  useEffect(() => {
    if (orders) {
      setData(orders);
    }
  }, [orders]);

  const getPageData = () => {
    const filter = {
      page: page,
      search: search,
      status: statusTerm,
      order_type: orderType,
      orderBy: sortBy,
      order: sortOrder,
    };
    dispatch(getOrderListAsync(filter));
  };

  const updateSortOrder = (col: string) => {
    if (col === "user.name" || col === "merchant.name") {
      return false;
    }
    if (col === sortBy) {
      setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");
    } else {
      setSortBy(col);
      setSortOrder("ASC");
    }
  };

  const removeOrder = async () => {
    const res = await deleteOrder(deleteItem);
    setDeleteItem("");
    getPageData();
  };

  const handleClear = () => {
    setSearch("");
    setStatusTerm("");
    setOrderType("");
  };

  const sorting = (col: string) => {
    if (order === "ASC") {
      const sorted = [...data].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setData(sorted);
      setOrder("DESC");
    }
    if (order === "DESC") {
      const sorted = [...data].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setData(sorted);
      setOrder("ASC");
    }
  };

  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-11">
              <div className="page-header-title">
                <h5 className="m-b-10">Order</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <a>Orders</a>
                </li>
              </ul>
            </div>
            <BackBtn />
          </div>
        </div>
      </div>

      <div className="main-body">
        <div className="page-wrapper">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header">
                  <Row className="mb-2">
                    <Col auto>
                      <h5>Orders</h5>
                    </Col>
                    <Col md={2} >
                      {/* <Link className="btn btn-primary" to="/order/create">
                        Add Order
                      </Link> */}
                    </Col>
                  </Row>

                  <Row>
                    <Col md={4}>
                      <input
                        type="text"
                        placeholder="Search Order Number"
                        className="form-control"
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                      />
                    </Col>

                    <Col md={3}>
                      <select
                        placeholder="Select Status"
                        className="form-control form-select"
                        value={statusTerm}
                        onChange={(e) => {
                          setStatusTerm(e.target.value);
                        }}
                      >
                        <option value="">Select Status</option>
                        <option value="Pending">Pending</option>
                        <option value="Accepted">Accepted</option>
                        <option value="Rejected">Rejected</option>
                        <option value="Delivered">Delivered</option>
                      </select>
                    </Col>
                    <Col md={3}>
                      <select
                        placeholder="Select Status"
                        className="form-control form-select"
                        value={orderType}
                        onChange={(e) => {
                          setOrderType(e.target.value);
                        }}
                      >
                        <option value="">Select Order Type</option>
                        <option value="Delivery">Delivery</option>
                        <option value="eGift">eGift</option>
                      </select>
                    </Col>
                    <Col md={2}>
                      <button
                        type="button"
                        className="form-control btn btn-warning"
                        onClick={() => {
                          handleClear();
                        }}
                      >
                        Reset
                      </button>
                    </Col>
                  </Row>
                </div>
                <div className="card-block table-border-style">
                  {status === "loading" ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : status === "failed" ? (
                    <Alert key={"danger"} variant={"danger"}>
                      Somthing went wrong please try again
                    </Alert>
                  ) : (
                    <>
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              {ModuleConfig?.ModuleConfig?.listColumnOrder?.map(
                                (column: string, index: number) => {
                                  return (
                                    <th
                                    key={`head_${index}`}
                                    onClick={() => updateSortOrder(column)}
                                    >
                                      {
                                        ModuleConfig?.ModuleConfig?.listColumnLabel[column]
                                      }
                                    </th>
                                  );
                                }
                              )}
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.length >= 1 ? (
                              data
                                .filter((val: { status: any }) => {
                                  if (statusTerm === "") {
                                    return val;
                                  } else if (
                                    val?.status === statusTerm
                                    // .includes(search.toLocaleLowerCase())
                                  ) {
                                    return val;
                                  }
                                })
                                .map(
                                  (
                                    order: OrderDetailInterface,
                                    index: number
                                  ) => {
                                    return (
                                      <tr key={`table_row_${index}`}>
                                        <>
                                          {ModuleConfig?.ModuleConfig?.listColumnOrder?.map(
                                            (
                                              column: string,
                                              colIndex: number
                                            ) => {
                                              return (
                                                <td
                                                  key={`table_row_${index}_${colIndex}`}
                                                >
                                                  {/* column - status */}
                                                  {colIndex == 4 ? (
                                                    <span
                                                      className={
                                                        "label " +
                                                        (getPropByString(
                                                          order,
                                                          column
                                                        ) == "Accepted"
                                                          ? "label-success"
                                                          : getPropByString(
                                                              order,
                                                              column
                                                            ) === "Delivered"
                                                          ? "label label-info"
                                                          : getPropByString(
                                                              order,
                                                              column
                                                            ) === "Pending"
                                                          ? "label label-dark"
                                                          : "label label-danger")
                                                      }
                                                    >
                                                      {getPropByString(
                                                        order,
                                                        column
                                                      )}
                                                    </span>
                                                  ) : (
                                                    <>
                                                      {/* column - order Type */}
                                                      {colIndex == 2 ? (
                                                        <span
                                                          className={
                                                            "label " +
                                                            (getPropByString(
                                                              order,
                                                              column
                                                            ) == "eGift"
                                                              ? "label-eGift"
                                                              : "label-delivery")
                                                          }
                                                        >
                                                          {getPropByString(
                                                            order,
                                                            column
                                                          )}
                                                        </span>
                                                      ) : (
                                                        <>
                                                          {/* column - amount */}
                                                          {colIndex == 3 ? (
                                                            <b>
                                                              {" "}
                                                              ${" "}
                                                              {Number(
                                                                getPropByString(
                                                                  order,
                                                                  column
                                                                )
                                                              ).toFixed(2)}
                                                            </b>
                                                          ) : (
                                                            getPropByString(
                                                              order,
                                                              column
                                                            )
                                                          )}{" "}
                                                        </>
                                                      )}
                                                    </>
                                                  )}
                                                </td>
                                              );
                                            }
                                          )}

                                          <td>
                                            <Link
                                              className="btn btn-icon btn-success"
                                              to={`/order/detail/${
                                                order[
                                                  ModuleConfig.ModuleConfig?.primaryKey
                                                ]
                                              }`}
                                              title="View"
                                            >
                                              <i className="feather icon-eye"></i>
                                            </Link>
                                          </td>
                                        </>
                                      </tr>
                                    );
                                  }
                                )
                            ) : (
                              <tr>
                                <td
                                  colSpan={
                                    ModuleConfig?.ModuleConfig?.listColumnOrder?.length + 1
                                  }
                                >
                                  No Record.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <CustomPagination
                        curretnPage={page}
                        total={totalRecord}
                        limit={10}
                        setPage={setPage}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {deleteItem ? (
        <Modal show={true} onHide={() => setDeleteItem("")}>
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete Order?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setDeleteItem("")}>
              Close
            </Button>
            <Button variant="primary" onClick={removeOrder}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  );
};
export default OrderList;
