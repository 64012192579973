import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../stores/hooks";
import { getProductDetailAsync, ProductState } from "../ProductSlice";
import { Formik } from "formik";
import { ProductQtyInterface } from "../models/ProductQtyInterface";
import ModuleConfig from "../config/ModuleConfig";
import FormField from "../../common/FormField";
import { useEffect, useRef, useState } from "react";
import { productQtyUpdate } from "../ProductAPI";
import BackBtn from "../../common/BackBtn";

/**
 * Producting Favourites list page
 */
const ProductQtyUpdate = () => {
  const dispatch = useAppDispatch();
  const { status, detail } = useAppSelector(ProductState);
  const params = useParams();
  const navigator = useNavigate();

  const [addQty, setAddQty] = useState<number>(0);

  useEffect(() => {
    if (params.id) {
      dispatch(getProductDetailAsync(params.id));
    }
  }, [params, dispatch]);

  const handleSubmit = async (
    values: ProductQtyInterface,
    formMethod: any
  ) => {
    if (params.id) {

      const copyObj = { ...values };
      copyObj.product_option_detail = productOption;

      let total = 0;
      for (let i = 0; i < productOption?.options[0]?.option_values?.length; i++) {
        total = total + Number(productOption?.options[0]?.option_values[i]?.option_value);
      }

      if (total != copyObj.add_qty) {
        $(".option_value").addClass("is-invalid");
        alert("Quantity mismatch.");
        return;
      }

      const res: any = await productQtyUpdate(params.id, copyObj);
      if (res.errors && res.message) {
        const errors: any = {};
        const errorKeys = Object.keys(res.errors);
        for (var i = 0; i < errorKeys.length; i++) {
          errors[errorKeys[i]] = res.errors[errorKeys[i]][0];
        }
        formMethod.setErrors(errors);
      } else {
        navigator("/product");
      }
    }
  };

  // const setTotalValue = (e : any, values : any ) => {
  //   let addedQty = Number(e.target.value);
  //   let availableQty = Number(values.available_qty);
  //   let totalQty = addedQty + availableQty;
  // }

  //================================================

  useEffect(() => {
    var data: any = [];
    if (detail?.product_option_detail) {
      detail?.product_option_detail?.map((item: any, index: any) => {
        data.push({
          option_key: item.option_value,
          option_value: 0 //item.qty
        });

        setProductOption(
          {
            options: [{
              option_name: detail?.product_option_detail[0]?.option_name,
              option_values: data
            }]
          }
        );
      })
    }
  }, [detail]);

  const [productOption, setProductOption] = useState<any>(
    {
      options: [
        {
          option_name: "",
          option_values: [{
            option_key: "",
            option_value: ""
          }]
        }
      ]
    }
  );

  const addBranch = (e: any) => {
    let temp = { ...productOption };
    temp.options.push(
      {
        option_name: "",
        option_values: [{
          option_key: "",
          option_value: ""
        }]
      }
    );
    setProductOption(temp);
  };

  const deleteBranch = (i: any) => {
    let temp = { ...productOption }
    temp.options.splice(i, 1)
    setProductOption(temp)
  }

  const addContact = (e: any, i: any) => {
    let temp = { ...productOption };
    temp.options[i].option_values.push({
      option_key: "",
      option_value: 0
    });
    setProductOption(temp);
  };

  const deleteContact = (i: any, j: any) => {
    let temp = { ...productOption }
    temp.options[i].option_values.splice(j, 1)
    setProductOption(temp)
  }

  const handleBranchChange = (e: any, i: any) => {
    let temp = { ...productOption }
    temp.options[i][e.target.name] = e.target.value
    setProductOption(temp)
  }

  const handleContactChange = (e: any, i: any, j: any) => {
    let temp = { ...productOption }
    let data = {
      option_key: $(`#option_key_${i}_${j}`).val(),
      option_value: $(`#option_value_${i}_${j}`).val()
    };

    temp.options[i].option_values[j] = data;
    setProductOption(temp)
  }

  useEffect(() => {
    setInterval(() => {
      $('.option_name').prop("disabled", true);
      $('.option_key').prop("disabled", true);
    }, 1000);
  }, [detail])

  useEffect(() => {
    let total = 0;
    for (let i = 0; i < productOption?.options[0]?.option_values?.length; i++) {
      total = total + Number(productOption?.options[0]?.option_values[i]?.option_value);
    }
    let total2 = $('#add_qty').val();
    if (total != Number(total2)) {
      $(".option_value").addClass("is-invalid");
      $(".option_value").removeClass("is-valid");
    } else {
      $(".option_value").removeClass("is-invalid");
      $(".option_value").addClass("is-valid");
    }
  }, [productOption, addQty])

  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-11">
              <div className="page-header-title">
                <h5 className="m-b-10">Product</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/product">Products</Link>
                </li>
                <li className="breadcrumb-item">
                  <a>Update Stock </a>
                </li>
              </ul>
            </div>
            <BackBtn />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>Update Stock </h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <Formik
                    initialValues={
                      detail[ModuleConfig.ModuleConfigQty.primaryKey] ===
                        params.id
                        ? detail
                        : ModuleConfig.ModuleConfigQty.formDefaultValue
                    }
                    validate={(values) => {
                      const errors: any = {};
                      const fields = Object.keys(
                        ModuleConfig.ModuleConfigQty.formFields
                      );
                      for (let index = 0; index < fields.length; index++) {
                        if (
                          !values[fields[index]] &&
                          ModuleConfig.ModuleConfigQty.formFields[fields[index]]
                            .isRequired
                        ) {
                          errors[fields[index]] = "Required";
                        }
                      }

                      return errors;
                    }}
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          {Object.keys(
                            ModuleConfig.ModuleConfigQty.formFields
                          ).map((field: string, index: number) => {
                            const fieldDetails =
                              ModuleConfig.ModuleConfigQty.formFields[field];
                            return (
                              <div
                                key={`field_${index}`}
                                className={fieldDetails.colWidth}
                              >
                                {field === 'add_qty' ? <>
                                  <FormField
                                    name={field}
                                    readOnly={fieldDetails?.readOnly ? true : false}
                                    fieldDetails={fieldDetails}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    setFieldValue={setFieldValue}
                                    handleChange={(e: any) => {
                                      let addedQty = Number(e.target.value);
                                      let availableQty = Number(values.available_qty);
                                      let totalQty = addedQty + availableQty;
                                      setFieldValue('total_qty', totalQty);
                                      setFieldValue('add_qty', addedQty);
                                      setAddQty(e.target.value);
                                    }}
                                    handleBlur={handleBlur}
                                  />
                                </> : <>
                                  <FormField
                                    name={field}
                                    readOnly={
                                      fieldDetails?.readOnly ? true : false
                                    }
                                    fieldDetails={fieldDetails}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    setFieldValue={setFieldValue}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                  />
                                </>}

                              </div>
                            );
                          })}
                        </div>

                        <hr />

                        {/* [Add More Option] Start */}
                        <div className="form-group mb-4">
                          <h5>Product Options</h5>
                          {/* <button
                              type="button"
                              className="btn btn-primary float-right mt--40"
                              onClick={addBranch}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                className="feather feather-plus-circle"
                              >
                                <circle cx="10" cy="10" r="8"></circle>
                                <line x1="10" y1="6" x2="10" y2="14"></line>
                                <line x1="6" y1="10" x2="14" y2="10"></line>
                              </svg>
                            </button> */}
                        </div>

                        <div className="form-group mb-4">
                          {productOption?.options.map((branch: any, i: any) => (
                            <div className="border p-3 mb-4">

                              <div className="row">
                                <div className="col-md-11">
                                  <input name='option_name' className="form-control m-1 option_name" id={`option_name_${i}`} placeholder='Option Name' onChange={e => handleBranchChange(e, i)} value={productOption?.options[i].option_name} />
                                </div>
                                <div className="col-md-1">
                                  {/* <button type="button" className="btn btn-danger" onClick={e => deleteBranch(i)}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        className="feather feather-minus-circle"
                                      >
                                        <circle cx="10" cy="10" r="8" />
                                        <line
                                          x1="6"
                                          y1="10"
                                          x2="14"
                                          y2="10"
                                        />
                                      </svg>
                                    </button> */}
                                </div>
                              </div>

                              {branch.option_values.map((contact: any, j: any) => (
                                <div className="row" id="inputs_div">
                                  <div className="col-md-5">
                                    <input name='option_key' className="form-control m-1 option_key" id={`option_key_${i}_${j}`} placeholder='Option Key' onChange={(e: any) => { handleContactChange(e, i, j) }} value={productOption?.options[i].option_values[j].option_key} />
                                  </div>
                                  <div className="col-md-5">
                                    <input type="number" name='option_value' className="form-control m-1 option_value" id={`option_value_${i}_${j}`} placeholder='Option Value' onChange={(e: any) => { handleContactChange(e, i, j) }} value={productOption?.options[i].option_values[j].option_value} />
                                  </div>

                                  {j >= detail?.product_option_detail?.length ?
                                    <div className="col-md-2">
                                      <button className="btn btn-danger" type="button" onClick={e => deleteContact(i, j)} >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          stroke="currentColor"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          className="feather feather-minus-circle"
                                        >
                                          <circle cx="10" cy="10" r="8" />
                                          <line
                                            x1="6"
                                            y1="10"
                                            x2="14"
                                            y2="10"
                                          />
                                        </svg>
                                      </button>
                                    </div>
                                    : null}

                                </div>
                              ))}
                              <button className="btn btn-primary " type="button" onClick={e => addContact(e, i)}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-plus-circle"
                                >
                                  <circle cx="10" cy="10" r="8"></circle>
                                  <line x1="10" y1="6" x2="10" y2="14"></line>
                                  <line x1="6" y1="10" x2="14" y2="10"></line>
                                </svg>
                              </button>
                            </div>
                          ))}
                        </div>
                        {/* [Add More Option] End */}


                        <hr />
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={status === "loading"}
                        >
                          Submit
                        </button>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProductQtyUpdate;
