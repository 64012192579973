import React, { useCallback, useEffect, useState } from "react";
import Dropzone, { useDropzone } from 'react-dropzone';
import { uploadProductImage } from "../../product/ProductAPI";
import { useParams } from "react-router-dom";

const MyDropzone = (props: any ) => {
  const params = useParams();
  const [files, setFiles] = useState<any[]>([]);
  const {getRootProps, getInputProps} = useDropzone({});
  const { setFieldValue } = props;

  const uploadImage = async (file : any) => {
    let data : any = {
       file : file
    }
      const res: any = await uploadProductImage(file[0]);
      if (res.errors && res.message) {
        const errors: any = {};
        const errorKeys = Object.keys(res.errors);
        for (var i = 0; i < errorKeys.length; i++) {
          errors[errorKeys[i]] = res.errors[errorKeys[i]][0];
        }
      } else {
        console.log(res.data?.files);
        let filearray : any;
        if(props.values?.image){
          filearray = props.values?.image;
        }else{
          filearray = [];
        }
        filearray.push(res.data?.files);
        setFieldValue(props.name,filearray);
        console.log("image upload done");
      }
  };

  const onDrop = (acceptedFiles:any) => {
      uploadImage(acceptedFiles);
  }
  

  return (
    <Dropzone 
    onDrop={onDrop}
    minSize={0}
    maxSize={5242880}
    >
      {({getRootProps, getInputProps}) => (
      
          <div {...getRootProps()} className="dropzone" >
            <input {...getInputProps()} />
            <p>Upload file One by one here....</p>
          </div>
       
      )}

     
    </Dropzone>
    
  );
};

export default MyDropzone;
