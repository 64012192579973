import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../stores';
import { MerchantDetailInterface } from './models/MerchantDetailInterface';
import { createMerchant, deleteMerchant, getMerchantDetail, getMerchantList, updateMerchant } from './MerchantAPI';

export interface MerchantStateInterface {
  merchants: MerchantDetailInterface[];
  favourites: MerchantDetailInterface[];
  totalRecord: number;
  status: 'idle' | 'loading' | 'failed' | 'created' | 'updated' | 'deleted';
  detail: MerchantDetailInterface,
}

const initialState: MerchantStateInterface = {
  merchants: [],
  favourites: [],
  totalRecord: 0,
  status: 'idle',
  detail: {
    id: "",
    name: "",
    merchant_detail : []
  }
};

export const getMerchantListAsync = createAsyncThunk(
  'merchant/list',
  async (data : any) => {
    const response = await getMerchantList(data);
    return response.data;
  }
);
export const getMerchantDetailAsync = createAsyncThunk(
  'merchant/detail',
  async (index: string) => {
    const response = await getMerchantDetail(index);
    return response.data;
  }
);

export const createMerchantAsync = createAsyncThunk(
  'merchant/create',
  async (data: MerchantDetailInterface) => {
    const response = await createMerchant(data);
    return response.data;
  }
);

export const deleteMerchantAsync = createAsyncThunk(
  'merchant/delete',
  async (index: string) => {
    const response = await deleteMerchant(index);
    return response.data;
  }
);

export const updateMerchantAsync = createAsyncThunk(
  'merchant/update',
  async (data: {
    index: string, data: MerchantDetailInterface
  }) => {
    const response = await updateMerchant(data.index, data.data);
    return response.data;
  }
);

export const merchantSlice = createSlice({
  name: 'merchant',
  initialState,
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getMerchantListAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getMerchantListAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.merchants = action.payload.data;
        state.totalRecord = action.payload.total

      })
      .addCase(getMerchantListAsync.rejected, (state) => {
        state.status = 'failed';
      })
      // GET DETAIL
      .addCase(getMerchantDetailAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getMerchantDetailAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.detail = action.payload;
      })
      .addCase(getMerchantDetailAsync.rejected, (state) => {
        state.status = 'failed';
      })
      // CREATE
      .addCase(createMerchantAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createMerchantAsync.fulfilled, (state, action) => {
        state.status = 'created';
        state.detail = action.payload;
      })
      .addCase(createMerchantAsync.rejected, (state) => {
        state.status = 'failed';
      })
      // UPDATE
      .addCase(updateMerchantAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateMerchantAsync.fulfilled, (state, action) => {
        state.status = 'updated';
        state.detail = action.payload;
      })
      .addCase(updateMerchantAsync.rejected, (state) => {
        state.status = 'failed';
      })
      // DELETE
      .addCase(deleteMerchantAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteMerchantAsync.fulfilled, (state, action) => {
        state.status = 'deleted';
        state.detail = action.payload;
      })
      .addCase(deleteMerchantAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {  } = merchantSlice.actions;
export const MerchantState = (state: RootState) => state.merchant;
export default merchantSlice.reducer;
