

const ModuleConfig = {
    "primaryKey": "id",
    "formDefaultValue": {
        "name": "",
        "description": "",
        "category_id": "",
        "status": "",
        "available_qty": 0,
        "add_qty": 0,
        "total_qty": 0,
        "options": [],
        "product_option_detail": [],
        

    },
    "formFields": {
        "category_id": {
            "label": "Select Category",
            "placeholder": "Select Category",
            "isRequired": false,
            "colWidth": "col-md-6",
            "inputType": "select",
            "asynchLoad": true,
            "dataUrl": "/categories",
            "keyValue": "id",
            "keylabel": "name",
            "options": [{
                value: "Test",
                label: "Test"
            }],
            "type": "text"
        },
        "brand_id": {
            "label": "Select Brand",
            "placeholder": "Select Brand",
            "isRequired": false,
            "colWidth": "col-md-6",
            "inputType": "select",
            "asynchLoad": true,
            "dataUrl": "/brands",
            "keyValue": "id",
            "keylabel": "name",
            "options": [{
                value: "Test",
                label: "Test"
            }],
            "type": "text"
        },
        "merchant_id": {
            "label": "Select Merchant",
            "placeholder": "Select Merchant",
            "hideFor": "Merchant",
            "isRequired": false,
            "colWidth": "col-md-6",
            "inputType": "select",
            "asynchLoad": true,
            "dataUrl": "/merchants",
            "keyValue": "id",
            "keylabel": "name",
            "options": [{
                value: "Test",
                label: "Test"
            }],
            "type": "text"
        },
        "name": {
            "label": "Name",
            "isRequired": false,
            "colWidth": "col-md-6",
            "inputType": "text",
            "type": "text"
        },
        "description": {
            "label": "Description",
            "isRequired": false,
            "colWidth": "col-md-12",
            "inputType": "textarea",
            "type": "text"
        },
        "gift_type": {
            "label": "Gift type",
            "placeholder": "Select Gift type",
            "isRequired": false,
            "colWidth": "col-md-6",
            "inputType": "select",
            "asynchLoad": false,
            "options": [{
                value: "eGift",
                label: "eGift"
            }, {
                value: "Delivery",
                label: "Delivery"
            }],
            "type": "text"
        },

        "available_qty": {
            "label": "Available Qty",
            "readOnly": true,
            "isRequired": false,
            "colWidth": "col-md-6",
            "inputType": "text",
            "type": "number",
        },
        "price": {
            "label": "Price",
            "isRequired": false,
            "colWidth": "col-md-6",
            "inputType": "text",
            "type": "number"
        },

        "status": {
            "label": "Status",
            "isRequired": false,
            "colWidth": "col-md-6",
            "placeholder": "Select Status",
            "inputType": "select",
            "asynchLoad": false,
            "dataUrl": "/categories",
            "options": [{
                value: "Active",
                label: "Active"
            }, {
                value: "Inactive",
                label: "Inactive"
            }, {
                value: "Pending",
                label: "Pending"
            }],
            "type": "text"
        },
        "image": {
            "label": "Image",
            "isRequired": false,
            "colWidth": "col-md-6",
            "inputType": "dropzone",
            "type": "text"
        }
       
    },


    "listColumnOrder": [
        "name",
        "price",
        "available_qty",
        "gift_type",
        "status"
    ]
}


const ModuleConfigQty = {
    "primaryKey": "id",
    "formDefaultValue": {
        "available_qty": 0,
        "add_qty": 0,
        "total_qty": 0

    },
    "formFields": {

        "available_qty": {
            "label": "Available Qty",
            "readOnly": true,
            "colWidth": "col-md-4",
            "inputType": "text",
            "type": "number"
        },
        "add_qty": {
            "label": "Add New Qty",
            "isRequired": false,
            "colWidth": "col-md-4",
            "inputType": "text",
            "type": "number"
        },
        "total_qty": {
            "label": "Total Qty",
            "readOnly": true,
            "colWidth": "col-md-4",
            "inputType": "text",
            "type": "number"
        }

    },

}


export default { ModuleConfig, ModuleConfigQty }