import { Route } from "react-router-dom";
import BrandCreate from "./pages/BrandCreate";
import BrandList from "./pages/BrandList";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "brand"; 
const BrandRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<BrandList />} key={`${MODULE_ROUTE}_index`} />,
    <Route path={`${MODULE_ROUTE}/create`} element={<BrandCreate />}  key={`${MODULE_ROUTE}_create`}  />,
    <Route path={`${MODULE_ROUTE}/update/:id`} element={<BrandCreate />} key={`${MODULE_ROUTE}_edit`} />,
  ];

export default BrandRoute;