import { Link, useNavigate, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../stores/hooks"
import { getOrderDetailAsync, OrderState } from "../OrderSlice"
import { Formik } from "formik"
import { OrderDetailInterface } from "../models/OrderDetailInterface"
import { OrderProductInterface } from "../models/OrderProductInterface"
import ModuleConfig from "../config/ModuleConfig"
import FormField from "../../common/FormField"
import { useEffect } from "react"
import { createOrder, updateOrder } from "../OrderAPI"
import { getPropByString } from "../../../utils/CommonFunction"
import BackBtn from "../../common/BackBtn"

/**
 * Ordering Favourites list page
 */
const OrderCreate = () => {
  const dispatch = useAppDispatch()
  const { status, detail } = useAppSelector(OrderState)
  const params = useParams()
  const navigator = useNavigate()

  useEffect(() => {
    if (params.id) {
      dispatch(getOrderDetailAsync(params.id))
    }
  }, [params, dispatch])

  const handleSubmit = async (
    values: OrderDetailInterface,
    formMethod: any
  ) => {
    if (params.id) {
      const res: any = await updateOrder(params.id, values)
      if (res.errors && res.message) {
        const errors: any = {}
        const errorKeys = Object.keys(res.errors)
        for (var i = 0; i < errorKeys.length; i++) {
          errors[errorKeys[i]] = res.errors[errorKeys[i]][0]
        }
        formMethod.setErrors(errors)
      } else {
        navigator("/order")
      }
    } else {
      const res: any = await createOrder(values)
      if (res.errors && res.message) {
        const errors: any = {}
        const errorKeys = Object.keys(res.errors)
        for (var i = 0; i < errorKeys.length; i++) {
          errors[errorKeys[i]] = res.errors[errorKeys[i]][0]
        }
        formMethod.setErrors(errors)
      } else {
        navigator("/order")
      }
    }
  }

  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-11">
              <div className="page-header-title">
                <h5 className="m-b-10">Order</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/order">Orders</Link>
                </li>
                <li className="breadcrumb-item">
                  <a>View Order</a>
                </li>
              </ul>
            </div>
            <BackBtn />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>View Order</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  {<Formik
                    initialValues={
                      detail[ModuleConfig.ModuleConfig.primaryKey] === params.id
                        ? detail
                        : ModuleConfig.ModuleConfig.formDefaultValue
                    }
                    validate={(values) => {
                      const errors: any = {}
                      const fields = Object.keys(
                        ModuleConfig.ModuleConfig.formFields
                      )
                      for (let index = 0; index < fields.length; index++) {
                        if (
                          !values[fields[index]] &&
                          ModuleConfig.ModuleConfig.formFields[fields[index]]
                            .isRequired
                        ) {
                          errors[fields[index]] = "Required"
                        }
                      }

                      return errors
                    }}
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          {Object.keys(
                            ModuleConfig.ModuleConfig.formFields
                          ).map((field: string, index: number) => {
                            const fieldDetails =
                              ModuleConfig.ModuleConfig.formFields[field]
                            return (
                              <div
                                key={`field_${index}`}
                                className={fieldDetails.colWidth}
                              >
                                {/* <FormField
                                  name={field}
                                  fieldDetails={fieldDetails}
                                  values={values}
                                  errors={errors}
                                  touched={touched}
                                  setFieldValue={setFieldValue}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                /> */}
                                <label>
                                {field} 
                                </label>
                              </div>
                            )
                          })}
                        </div>

                        <div className="card-header">
                          <h5>Products </h5>
                        </div>
                        <div className="table-responsive">
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                {ModuleConfig.ModuleConfigProduct.listColumnOrder.map(
                                  (column: string, index: number) => {
                                    return (
                                      <th key={`head_${index}`}>
                                        {
                                          ModuleConfig.ModuleConfigProduct
                                            .listColumnLabel[column]
                                        }
                                      </th>
                                    )
                                  }
                                )}
                                <th>Sub Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              {detail.products?.length >= 1 ? (
                                detail.products.map(
                                  (
                                    product: OrderProductInterface,
                                    index: number
                                  ) => {
                                    return (
                                      <tr key={`table_row_${index}`}>
                                        <>
                                          {ModuleConfig.ModuleConfigProduct.listColumnOrder.map(
                                            (
                                              column: string,
                                              colIndex: number
                                            ) => {
                                              return (
                                                <td
                                                  key={`table_row_${index}_${colIndex}`}
                                                >
                                                  column - status
                                                  {colIndex == 0 ? (
                                                    <a
                                                      href={`/product/update/${getPropByString(
                                                        product,
                                                        "product_id"
                                                      )}`}
                                                      target="_blank"
                                                    >
                                                      {getPropByString(
                                                        product,
                                                        column
                                                      )}
                                                    </a>
                                                  ) : (
                                                    <>
                                                      column - amount
                                                      {colIndex == 2 ? (
                                                        <>
                                                          {" "}
                                                          ${" "}
                                                          {Number(
                                                            getPropByString(
                                                              product,
                                                              column
                                                            )
                                                          ).toFixed(2)}{" "}
                                                        </>
                                                      ) : (
                                                        getPropByString(
                                                          product,
                                                          column
                                                        )
                                                      )}
                                                    </>
                                                  )}
                                                </td>
                                              )
                                            }
                                          )}

                                          <td>
                                            <b>
                                              ${" "}
                                              {Number(
                                                getPropByString(
                                                  product,
                                                  "price"
                                                ) *
                                                  getPropByString(
                                                    product,
                                                    "qty"
                                                  )
                                              ).toFixed(2)}{" "}
                                            </b>
                                          </td>
                                        </>
                                      </tr>
                                    )
                                  }
                                )
                              ) : (
                                <tr>
                                  <td
                                    colSpan={
                                      ModuleConfig.ModuleConfig.listColumnOrder
                                        .length + 1
                                    }
                                  >
                                    No Record.
                                  </td>
                                </tr>
                              )}
                              {detail.products?.length >= 1 ? (
                                <tr>
                                  <td colSpan={3}>
                                    <b>Total Amount</b>
                                  </td>
                                  <td>
                                    <b>
                                      ${" "}
                                      {Number(
                                        getPropByString(detail, "total_price")
                                      ).toFixed(2)}{" "}
                                    </b>
                                  </td>
                                </tr>
                              ) : (
                                <></>
                              )}
                            </tbody>
                          </table>
                        </div>

                        <hr />
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={status === "loading"}
                        >
                          Submit
                        </button>
                      </form>
                    )}
                  </Formik> }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
<h1>Test</h1>
                    {/* { const fields = Object.keys(
                        ModuleConfig.ModuleConfig.formFields
                      )
                      for (let index = 0; index < fields.length; index++) {
                        if (
                          !values[fields[index]] &&
                          ModuleConfig.ModuleConfig.formFields[fields[index]]
                            .isRequired
                        ) {
                          errors[fields[index]] = "Required"
                        }
                      }
                    } */}



        {Object.keys(
                            ModuleConfig.ModuleConfig.formFields
                          ).map((field: string, index: number) => {
                                <div className="row">
                                    <div className="col-6">
                                        <label>Merchnat Name :</label> <br />
                                        <label>Riya</label>
                                    </div>
                                    <div className="col-6">
                                        <label>Merchnat Name :</label> <br />
                                        <label>Riya</label>
                                    </div>
                                </div>
                    })}

    </>
  )
}
export default OrderCreate
