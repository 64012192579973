const Config = {
  loginAsUrl: "https://merchant.coupid.com.au/login-as/",
  sourceImageURL: "https://api.coupid.com.au/public/",
  baseURL: "https://api.coupid.com.au/api/",

  // loginAsUrl: "http://192.168.1.5:8000/login-as/",
  // sourceImageURL: "http://192.168.1.5:8000/",
  // baseURL: "http://192.168.1.5:8000/api/",
};
export default Config;
