import { useNavigate } from "react-router-dom";

const BackBtn = () => {
  let navigate = useNavigate();

  return (
    <div className="col-md-1">
      <button
        className="btn bg-primary text-white"
        onClick={() => {
          navigate(-1);
        }}
      >
        Back
      </button>
    </div>
  );
};

export default BackBtn;
