import { useEffect, useState } from "react"
import { Alert, Spinner } from "react-bootstrap"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../stores/hooks"
import { getOrderDetailAsync, OrderState } from "../OrderSlice"
import ModuleConfig from "./../config/ModuleConfig"
import { getPropByString } from "../../../utils/CommonFunction"
import { OrderProductInterface } from "../models/OrderProductInterface"
import { Formik } from "formik"
import AxiosInstance from "../../../utils/AxiosInstance"
import "react-toastify/dist/ReactToastify.css"
import { ToastContainer, toast } from "react-toastify"
import { appState } from "./../../../stores/appSlice"
import BackBtn from "../../common/BackBtn"
/**
 * Ordering view page
 */
const OrderView = () => {
  const dispatch = useAppDispatch()
  const { status, detail } = useAppSelector(OrderState)
  const params = useParams()
  const [code, setCode] = useState("")
  const { profile } = useAppSelector(appState)

  useEffect(() => {
    getOrderDetails()
  }, [params])

  const getOrderDetails = () => {
    if (params.id) {
      dispatch(getOrderDetailAsync(params.id))
    }
  }

  const verifyCode = async () => {
    var data = {
      code: code,
    }
    const res = await AxiosInstance.post(`order/verifycode/${params.id}`, data)
    
    if (res.data.status == 200) {
      toast.success("Order Delivered Successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      })
    } else {
      toast.error("Invalid Code!", {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
    getOrderDetails()
  }

  const handleFormSubmit = async (values: any) => {
    const res = await AxiosInstance.post(
      `order/updatedelivery/${params.id}`,
      values
    )
    if (res.data.status == 200) {
      toast.success("Courier details added!", {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
    getOrderDetails()
  }

  const changeStatus = async (e: any) => {
    const res = await AxiosInstance.post(`order/status/${params.id}`, {
      status: e,
    })
    if (res.data.status == 200) {
      toast.success("Status Changed!", {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
    getOrderDetails()
  }

  return (
    <>
      <div className="page-header">
        <ToastContainer />
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-11">
              <div className="page-header-title">
                <h5 className="m-b-10">Order</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/order">Orders</Link>
                </li>
                <li className="breadcrumb-item">
                  <a>View Order</a>
                </li>
              </ul>
            </div>
            <BackBtn />
          </div>
        </div>
      </div>

      <div className="main-body">
        <div className="page-wrapper">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header">
                  <h5>View Order</h5>
                </div>
                <div className="card-block table-border-style">
                  {status === "loading" ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : status === "failed" ? (
                    <Alert key={"danger"} variant={"danger"}>
                      Somthing went wrong please try again
                    </Alert>
                  ) : (
                    <>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-9">
                                <label
                                  style={{
                                    fontWeight: 900,
                                    marginBottom: "15px",
                                    fontSize: "20px",
                                    color: "black",
                                  }}
                                >
                                  Order Number :
                                </label>{" "}
                                <span
                                  style={{
                                    fontWeight: 900,
                                    fontSize: "20px",
                                    color: "black",
                                  }}
                                >
                                  {detail?.order_number}{" "}
                                  <span
                                    className={
                                      detail?.order_type == "eGift"
                                        ? "label  label-eGift"
                                        : "label  label-delivery"
                                    }
                                  >
                                    {detail?.order_type}
                                  </span>
                                </span>{" "}
                              </div>
                              <div className="col-3">
                                <label
                                  style={{
                                    fontWeight: 900,
                                    marginBottom: "15px",
                                    fontSize: "20px",
                                    color: "black",
                                  }}
                                >
                                  Order Status :
                                </label>{" "}
                                {profile?.role === "Merchant" &&
                                detail?.status === "Pending" ? (
                                  <>
                                    <button
                                      className="btn btn-success ml-5"
                                      onClick={() => {
                                        changeStatus("accept")
                                      }}
                                    >
                                      Accept
                                    </button>
                                    <button
                                      className="btn btn-danger ml-5"
                                      onClick={() => {
                                        changeStatus("reject")
                                      }}
                                    >
                                      Reject
                                    </button>
                                  </>
                                ) : (
                                  <span
                                    style={{
                                      fontSize: "18px",
                                    }}
                                    className={
                                      detail?.status == "Accepted"
                                        ? "label label-success"
                                        : detail?.status === "Delivered"
                                        ? "label label-info"
                                        : detail?.status === "Pending"
                                        ? "label label-dark"
                                        : "label label-danger"
                                    }
                                  >
                                    {detail?.status}
                                  </span>
                                )}
                              </div>
                            </div>
                            <br />
                            {profile?.role === "Merchant" &&
                            detail?.status === "Accepted" ? (
                              <>
                                <div className="row">
                                  <div className="col-2">
                                    <h3>Verification Code :</h3>
                                  </div>
                                  <div className="col-4">
                                    <input
                                      type="text" 
                                      value={code}
                                      name="code"
                                      onChange={(e) => {
                                        setCode(e.target.value)
                                      }}
                                      className="form-control"
                                      placeholder="Verification Code"
                                    />
                                  </div>
                                  <div className="col-5">
                                    <button
                                      className="btn btn-primary"
                                      onClick={() => verifyCode()}
                                    >
                                      {" "}
                                      Verify
                                    </button>
                                  </div>
                                </div>
                                <br /> <br />
                              </>
                            ) : null}

                            <div className="row">
                              <div className="col-6">
                                <span
                                  style={{ fontSize: "20px", color: "black" }}
                                >
                                  Merchant Details
                                </span>
                                <br />
                                <br />
                                <label
                                  style={{
                                    fontWeight: 900,
                                    marginBottom: "5px",
                                  }}
                                >
                                  Business Name :
                                </label>{" "}
                                <span>
                                  {
                                    detail?.merchant?.merchant_detail
                                      ?.business_name
                                  }
                                </span>{" "}
                                <br />
                                <label
                                  style={{
                                    fontWeight: 900,
                                    marginBottom: "5px",
                                  }}
                                >
                                  Business Address :
                                </label>{" "}
                                <span>
                                  {
                                    detail?.merchant?.merchant_detail
                                      ?.business_address
                                  }
                                </span>{" "}
                                <br />
                                <label
                                  style={{
                                    fontWeight: 900,
                                    marginBottom: "5px",
                                  }}
                                >
                                  Merchant Name :
                                </label>{" "}
                                <span>{detail?.merchant?.name}</span> <br />
                                <label
                                  style={{
                                    fontWeight: 900,
                                    marginBottom: "5px",
                                  }}
                                >
                                  Email :
                                </label>{" "}
                                <span>{detail?.merchant?.email}</span> <br />
                                <label
                                  style={{
                                    fontWeight: 900,
                                    marginBottom: "5px",
                                  }}
                                >
                                  Mobile Number :
                                </label>{" "}
                                <span>{detail?.merchant?.mobile}</span> <br />
                              </div>
                              <div className="col-6">
                                <span
                                  style={{ fontSize: "20px", color: "black" }}
                                >
                                  User Details
                                </span>
                                <br />
                                <br />
                                <label
                                  style={{
                                    fontWeight: 900,
                                    marginBottom: "5px",
                                  }}
                                >
                                  User Name :
                                </label>{" "}
                                <span>{detail?.user?.name}</span> <br />
                                <label
                                  style={{
                                    fontWeight: 900,
                                    marginBottom: "5px",
                                  }}
                                >
                                  Email :
                                </label>{" "}
                                <span>{detail?.user?.email}</span> <br />
                                <label
                                  style={{
                                    fontWeight: 900,
                                    marginBottom: "5px",
                                  }}
                                >
                                  Mobile Number :
                                </label>{" "}
                                <span>{detail?.user?.mobile}</span> <br />
                              </div>
                            </div>
                            <br />
                            <br />
                            <div className="row">
                              <div className="col-6">
                                <span
                                  style={{ fontSize: "20px", color: "black" }}
                                >
                                  Order Details
                                </span>
                                <br />
                                <br />
                                {profile?.role === "Merchant" ? (
                                  <></>
                                ) : (
                                  <>
                                    <label
                                      style={{
                                        fontWeight: 900,
                                        marginBottom: "5px",
                                      }}
                                    >
                                      Confirmation Code :
                                    </label>{" "}
                                    <span>{detail?.confirmation_code}</span>{" "}
                                    <br />
                                    <label
                                      style={{
                                        fontWeight: 900,
                                        marginBottom: "5px",
                                      }}
                                    >
                                      Order Link :
                                    </label>{" "}
                                    <span>
                                      <a href={detail?.order_link +"/"+ detail?.user?.name }>
                                        {detail?.order_link +"/"+ detail?.user?.name}
                                      </a>
                                    </span>{" "}
                                    <br />
                                    <label
                                      style={{
                                        fontWeight: 900,
                                        marginBottom: "5px",
                                      }}
                                    >
                                      Link Expiry Date :
                                    </label>{" "}
                                    <span>{detail?.link_expiry_date}</span>{" "}
                                    <br />
                                  </>
                                )}
                                <label
                                  style={{
                                    fontWeight: 900,
                                    marginBottom: "5px",
                                  }}
                                >
                                  Note (Message) :
                                </label>{" "}
                                <span>{detail?.note}</span> <br />
                              </div>
                              {detail?.order_type == "Delivery" ?
                              <div className="col-6">
                                <span
                                  style={{ fontSize: "20px", color: "black" }}
                                >
                                  Delivery Details
                                </span>
                                <br />
                                <br />
                                <label
                                  style={{
                                    fontWeight: 900,
                                    marginBottom: "5px",
                                  }}
                                >
                                  Address :
                                </label>{" "}
                                <span>{detail?.address}</span> <br />
                                <label
                                  style={{
                                    fontWeight: 900,
                                    marginBottom: "5px",
                                  }}
                                >
                                  Mobile Number :
                                </label>{" "}
                                <span>{detail?.mobile_number}</span> <br />
                                {detail?.delivery_detail === null &&
                                detail?.status === "Accepted" ? (
                                  <Formik
                                    initialValues={{
                                      courier_name: "",
                                      courier_number: "",
                                    }}
                                    validate={(values) => {
                                      const errors: any = {}
                                      if (!values.courier_name) {
                                        errors.courier_name = "Required field"
                                      }
                                      if (!values.courier_number) {
                                        errors.courier_number = "Required field"
                                      }
                                      return errors
                                    }}
                                    onSubmit={handleFormSubmit}
                                  >
                                    {({
                                      values,
                                      errors,
                                      touched,
                                      handleChange,
                                      handleBlur,
                                      handleSubmit,
                                      isSubmitting,
                                    }) => (
                                      <form onSubmit={handleSubmit}>
                                        <div className="form-group pb-1 mb-4">
                                          <label
                                            className="control-label col-xs-3 mb-1 pb-1"
                                            htmlFor="inputEmail"
                                          >
                                            Courier Name
                                          </label>
                                          <div className="col-xs-9">
                                            <input
                                              type="text"
                                              className="form-control cform-control"
                                              name="courier_name"
                                              placeholder="Courier Name"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values.courier_name}
                                            />
                                            <div className="error-message">
                                              {errors.courier_name &&
                                                touched.courier_name &&
                                                errors.courier_name}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="form-group pb-1 mb-4">
                                          <label
                                            className="control-label col-xs-3 mb-1 pb-1"
                                            htmlFor="inputEmail"
                                          >
                                            Courier Number
                                          </label>
                                          <div className="col-xs-9">
                                            <input
                                              type="text"
                                              className="form-control cform-control"
                                              name="courier_number"
                                              placeholder="Courier Number"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values.courier_number}
                                            />
                                            <div className="error-message">
                                              {errors.courier_number &&
                                                touched.courier_number &&
                                                errors.courier_number}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="form-group checkbox_group mb-4 pb-1">
                                          <div className="row">
                                            <div className="col-sm-12">
                                              <input
                                                type="submit"
                                                className="btn btn-primary btn_submit"
                                                value="Submit"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                    )}
                                  </Formik>
                                ) : (
                                  <>
                                    {detail?.status === "Accepted" ||
                                    detail?.status === "Delivered" ? (
                                      <>
                                        <label
                                          style={{
                                            fontWeight: 900,
                                            marginBottom: "5px",
                                          }}
                                        >
                                          Courier Name :
                                        </label>{" "}
                                        <span>
                                          {
                                            detail?.delivery_detail
                                              ?.courier_name
                                          }
                                        </span>{" "}
                                        <br />
                                        <label
                                          style={{
                                            fontWeight: 900,
                                            marginBottom: "5px",
                                          }}
                                        >
                                          Courier Number :
                                        </label>{" "}
                                        <span>
                                          {
                                            detail?.delivery_detail
                                              ?.courier_number
                                          }
                                        </span>{" "}
                                        <br />
                                        <label
                                          style={{
                                            fontWeight: 900,
                                            marginBottom: "5px",
                                          }}
                                        >
                                          Redemption Date :
                                        </label>{" "}
                                        <span>
                                          {
                                            detail?.redemption_date != "" && detail?.status == "Delivered" ? detail?.redemption_date : ""
                                          }
                                        </span>{" "}
                                        <br />
                                      </>
                                    ) : null}
                                  </>
                                )}
                              </div> : <> </>}
                            </div> 
                            <div className="card-header">
                              <h5>Products </h5>
                            </div>
                            <div className="table-responsive">
                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    {ModuleConfig.ModuleConfigProduct.listColumnOrder.map(
                                      (column: string, index: number) => {
                                        return (
                                          <th key={`head_${index}`}>
                                            {
                                              ModuleConfig.ModuleConfigProduct
                                                .listColumnLabel[column]
                                            }
                                          </th>
                                        )
                                      }
                                    )}
                                    <th>Sub Total</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {detail.products?.length >= 1 ? (
                                    detail.products.map(
                                      (
                                        product: OrderProductInterface,
                                        index: number
                                      ) => {
                                        return (
                                          <tr key={`table_row_${index}`}>
                                            <>
                                              {ModuleConfig.ModuleConfigProduct.listColumnOrder.map(
                                                (
                                                  column: string,
                                                  colIndex: number
                                                ) => {
                                                  return (
                                                    <td
                                                      key={`table_row_${index}_${colIndex}`}
                                                    >
                                                      {colIndex == 0 ? (
                                                        <>
                                                          <a
                                                            href={`/product/update/${getPropByString(
                                                              product,
                                                              "product_id"
                                                            )}`}
                                                            target="_blank"
                                                          >
                                                            {getPropByString(
                                                              product,
                                                              column
                                                            )}
                                                          </a>
                                                          <div>
                                                            {detail
                                                              .product_option_detail
                                                              ?.length >= 1 ? (
                                                              <>
                                                                {detail.product_option_detail.map(
                                                                  (
                                                                    item: any,
                                                                    index: number
                                                                  ) => {
                                                                    if (
                                                                      product.product_id ===
                                                                      item.product_id
                                                                    ) {
                                                                      return (
                                                                        <div>
                                                                          <b>
                                                                            {
                                                                              item?.option_name
                                                                            }{" "}
                                                                            :{" "}
                                                                          </b>{" "}
                                                                          {
                                                                            item?.option_value
                                                                          }
                                                                        </div>
                                                                      )
                                                                    }
                                                                    return null
                                                                  }
                                                                )}
                                                              </>
                                                            ) : null}
                                                          </div>
                                                        </>
                                                      ) : (
                                                        <>
                                                          {colIndex == 2 ? (
                                                            <>
                                                              {" "}
                                                              ${" "}
                                                              {Number(
                                                                getPropByString(
                                                                  product,
                                                                  column
                                                                )
                                                              ).toFixed(2)}{" "}
                                                            </>
                                                          ) : (
                                                            getPropByString(
                                                              product,
                                                              column
                                                            )
                                                          )}
                                                        </>
                                                      )}
                                                    </td>
                                                  )
                                                }
                                              )}

                                              <td>
                                                <b>
                                                  ${" "}
                                                  {Number(
                                                    getPropByString(
                                                      product,
                                                      "price"
                                                    ) *
                                                      getPropByString(
                                                        product,
                                                        "qty"
                                                      )
                                                  ).toFixed(2)}{" "}
                                                </b>
                                              </td>
                                            </>
                                          </tr>
                                        )
                                      }
                                    )
                                  ) : (
                                    <tr>
                                      <td
                                        colSpan={
                                          ModuleConfig.ModuleConfig
                                            .listColumnOrder.length + 1
                                        }
                                      >
                                        No Record.
                                      </td>
                                    </tr>
                                  )}
                                  {detail.products?.length >= 1 ? (
                                    <tr>
                                      <td colSpan={3}>
                                        <b>Total Amount</b>
                                      </td>
                                      <td>
                                        <b>
                                          ${" "}
                                          {Number(
                                            getPropByString(
                                              detail,
                                              "total_price"
                                            )
                                          ).toFixed(2)}{" "}
                                        </b>
                                      </td>
                                    </tr>
                                  ) : (
                                    <></>
                                  )}
                                </tbody>
                              </table>
                            </div>

                            <hr />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default OrderView
