const ModuleConfig = {
    "primaryKey": "id",
    "formDefaultValue" : {
        "name" : ""
    },
    "formFields": {
        "order_number": {
            "label": "Order number",
            "isRequired": false,
            "colWidth": "col-md-6",
            "inputType": "text",
            "type" :"text"
        },
        "user_id": {
            "label": "Select User",
            "placeholder": "Select User",
            "hideFor": "User",
            "isRequired": false,
            "colWidth": "col-md-6",
            "inputType": "select",
            "asynchLoad": true,
            "dataUrl": "/users",
            "keyValue": "id",
            "keylabel"  : "name",
            "options": [{
                value: "Test",
                label: "Test"
            }],
            "type": "text"
        },
        "merchant_id": {
            "label": "Select Merchant",
            "placeholder": "Select Merchant",
            "hideFor": "Merchant",
            "isRequired": false,
            "colWidth": "col-md-6",
            "inputType": "select",
            "asynchLoad": true,
            "dataUrl": "/merchants",
            "keyValue": "id",
            "keylabel"  : "name",
            "options": [{
                value: "Test",
                label: "Test"
            }],
            "type": "text"
        },
        "order_type": {
            "label": "Order Type",
            "isRequired": false,
            "colWidth": "col-md-6",
            "inputType": "text",
            "type" :"text"
        },
        "mobile_number": {
            "label": "Mobile Number",
            "isRequired": false,
            "colWidth": "col-md-6",
            "inputType": "text",
            "type" :"text"
        },
        "address": {
            "label": "Address",
            "isRequired": false,
            "colWidth": "col-md-6",
            "inputType": "text",
            "type" :"text"
        },
        "total_price": {
            "label": "Amount",
            "isRequired": false,
            "colWidth": "col-md-6",
            "inputType": "text",
            "type" :"text"
        },
        
        "transaction_id": {
            "label": "Transaction Id",
            "isRequired": false,
            "colWidth": "col-md-6",
            "inputType": "text",
            "type" :"text"
        },
        "confirmation_code": {
            "label": "Confirmation Code",
            "isRequired": false,
            "colWidth": "col-md-6",
            "inputType": "text",
            "type" :"text"
        },
        "order_link": {
            "label": "Order Link",
            "isRequired": false,
            "colWidth": "col-md-6",
            "inputType": "text",
            "type" :"text"
        },
        "link_expiry_date": {
            "label": "Link Expiry Date",
            "isRequired": false,
            "colWidth": "col-md-6",
            "inputType": "text",
            "type" :"text"
        },
        "note": {
            "label": "Note (Message)",
            "isRequired": false,
            "colWidth": "col-md-6",
            "inputType": "textarea",
            "type" :"text"
        },
        "status": {
            "label": "Status",
            "isRequired": false,
            "placeholder":"Select Status",
            "colWidth": "col-md-6",
            "inputType": "select",
            "type" :"select",
            "options" : [{
                "label" : "Pending",
                "value" : "Pending"
            },{
                "label" : "Accepted by Merchant",
                "value" : "Accepted"
            }, {
                "label" : "Rejected By Merchant",
                "value" : "Rejected"
            }, {
                "label" : "Cancelled By Admin",
                "value" : "Cancelled"
            }]
        }
        
    },
    "listColumnLabel" : {
        "order_number" :  "Order Number",
        "user.name": "User",
        "merchant.name": "Merchant",
        "order_type" :  "Order Type",
        "total_price" :  "Amount",
        "status" : "Status"   
    },
    "viewLabel" : {
        "order_number" :  "Order Number",
        "user.name": "User",
        "merchant.name": "Merchant",
        "order_type" :  "Order Type",
        "mobile_number" :  "Mobile Number",
        "merchant.merchant_detail.business_address" :  "Address",
        "total_price" :  "Amount",
        "transaction_id" :  "Transaction ID",
        "confirmation_code" :  "Confirmation Code",
        "order_link" :  "Order Link",
        "link_expiry_date" :  "Link Expiry Date",
        "note" :  "Note (Message)",
        "status" : "Status"   
    },
    listColumnOrder: [
        "order_number",  
        "user.name", 
        // "merchant.name", 
        "order_type", 
        "total_price", 
        "status"],
}

const ModuleConfigProduct = {
    "primaryKey": "id",
    "formDefaultValue": {
        "product_name": "",
        "price": 0,
        "qty": 0,
        "images": []
    },
    "formFields": {
        
        "product_name": {
            "label": "Name",
            "readOnly" : true,
            "colWidth": "col-md-4",
            "inputType": "text",
            "type": "text"
        },
        "price": {
            "label": "Price",
            "isRequired": false,
            "colWidth": "col-md-4",
            "inputType": "text",
            "type": "number"
        },
        "qty": {
            "label": "Quantity",
            "readOnly" : true,
            "colWidth": "col-md-4",
            "inputType": "text",
            "type": "number"
        },
        "images": {
            "label": "Image",
            "readOnly" : true,
            "colWidth": "col-md-4",
            "inputType": "text",
            "type": "text"
        },
    },
    "listColumnLabel" : {
        "product_name" :  "Name",
        "qty": "Quantity",
        "price": "Price",
        "images" :  "Image"
    },
    listColumnOrder: ["product_name", "qty", "price"],
   
}

export default { ModuleConfig,  ModuleConfigProduct }