import { Route } from "react-router-dom";
import OrderCreate from "./pages/OrderCreate";
import OrderList from "./pages/OrderList";
import OrderView from './pages/OrderView';
import OrderReview from './pages/OrderReview';

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "order"; 
const OrderRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<OrderList />} key={`${MODULE_ROUTE}_index`} />,
    <Route path={`${MODULE_ROUTE}/create`} element={<OrderCreate />}  key={`${MODULE_ROUTE}_create`}  />,
    <Route path={`${MODULE_ROUTE}/detail/:id`} element={<OrderView />} key={`${MODULE_ROUTE}_edit`} />,
    <Route path={`${MODULE_ROUTE}/view`} element={<OrderReview />} key={`${MODULE_ROUTE}_view`} />,

  ];

export default OrderRoute;