import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../stores/hooks";
import { getProductDetailAsync, ProductState } from "../ProductSlice";
import { Formik } from "formik";
import { ProductDetailInterface } from "../models/ProductDetailInterface";
import ModuleConfig from "../config/ModuleConfig";
import FormField from "../../common/FormField";
import { useEffect, useRef, useState } from "react";
import {
  createProduct,
  updateProduct,
  deleteProductImage,
} from "../ProductAPI";
import Config from "../../../utils/config";
import { FaTrash } from "react-icons/fa";
import { appState } from "../../../stores/appSlice";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import BackBtn from "../../common/BackBtn";

/**
 * Producting Favourites list page
 */
const ProductCreate = () => {
  const dispatch = useAppDispatch();
  const { status, detail } = useAppSelector(ProductState);
  const params = useParams();
  const navigator = useNavigate();
  const { profile } = useAppSelector(appState);

  const [availableQty, setAvailableQty] = useState<number>(0);

  useEffect(() => {
    if (params.id) {
      dispatch(getProductDetailAsync(params.id));
    }
  }, [params, dispatch]);

  const deleteImageFromProduct = async (deleteId: any) => {
    const confirmBox = window.confirm(
      "Do you really want to delete this Image?"
    );
    if (confirmBox === true) {
      const res: any = await deleteProductImage(deleteId);
      if (res.errors && res.message) {
        const errors: any = {};
        const errorKeys = Object.keys(res.errors);
        for (var i = 0; i < errorKeys.length; i++) {
          errors[errorKeys[i]] = res.errors[errorKeys[i]][0];
        }
      } else {
        if (params.id) {
          dispatch(getProductDetailAsync(params.id));
        }
      }
    }
  };

  const deleteImageFromProductArray = (file: any, values: any, id: any) => {
    const confirmBox = window.confirm(
      "Do you really want to delete this Image?"
    );
    if (confirmBox === true) {
      values.image.splice(values.image.indexOf(file), 1);
      dispatch(getProductDetailAsync(params.id || ""));
    }
  };

  const handleSubmit = async (
    values: ProductDetailInterface,
    formMethod: any
  ) => {

    if (params.id) {
      const copyObj = { ...values };
      copyObj.product_option_detail = productOption;

      // let total = 0;
      // for (let i = 0; i < productOption?.options[0]?.option_values?.length; i++) {
      //   total = total + Number(productOption?.options[0]?.option_values[i]?.option_value);
      // }
      // if (total != copyObj.available_qty && productOption?.options[0]?.option_name != "") {
      //   alert("Quantity mismatch.");
      //   return;
      // }

      const res: any = await updateProduct(params.id, copyObj);
      if (res.errors && res.message) {
        const errors: any = {};
        const errorKeys = Object.keys(res.errors);
        for (var i = 0; i < errorKeys.length; i++) {
          errors[errorKeys[i]] = res.errors[errorKeys[i]][0];
        }
        formMethod.setErrors(errors);
      } else if (res.data.status == "200") {
        toast.success("Product updated!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          navigator("/product");
        }, 1000);
      }
    } else {
      values.product_option_detail = productOption;
      let total = 0;
      for (let i = 0; i < productOption?.options[0]?.option_values?.length; i++) {
        total = total + Number(productOption?.options[0]?.option_values[i]?.option_value);
      }
      if (total != values.available_qty && productOption?.options[0]?.option_name != "") {
        alert("Quantity mismatch.");
        return;
      }

      const res: any = await createProduct(values);
      if (res.errors && res.message) {
        const errors: any = {};
        const errorKeys = Object.keys(res.errors);
        for (var m = 0; m < errorKeys.length; m++) {
          errors[errorKeys[m]] = res.errors[errorKeys[m]][0];
        }
        formMethod.setErrors(errors);
      } else if (res.data.status == "200") {
        toast.success("Product created!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          navigator("/product");
        }, 1000);
      }
    }
  };


  useEffect(() => {
    var data: any = [];
    if (detail?.product_option_detail) {
      detail?.product_option_detail?.map((item: any, index: any) => {
        data.push({
          option_key: item.option_value,
          option_value: item.qty
        });

        setProductOption(
          {
            options: [{
              option_name: detail?.product_option_detail[0]?.option_name,
              option_values: data
            }]
          }
        );
      })
    }
  }, [detail]);

  //====================================================

  const [productOption, setProductOption] = useState<any>(
    {
      options: [
        {
          option_name: "",
          option_values: [{
            option_key: "",
            option_value: 0
          }]
        }
      ]
    }
  );

  const addBranch = (e: any) => {
    let temp = { ...productOption };
    temp.options.push(
      {
        option_name: "",
        option_values: [{
          option_key: "",
          option_value: 0
        }]
      }
    );
    setProductOption(temp);
  };

  const addContact = (e: any, i: any) => {
    let temp = { ...productOption };
    temp.options[i].option_values.push({
      option_key: "",
      option_value: ""
    });
    setProductOption(temp);
  };

  const deleteBranch = (i: any) => {
    let temp = { ...productOption }
    temp.options.splice(i, 1)
    setProductOption(temp)
  }

  const deleteContact = (i: any, j: any) => {
    let temp = { ...productOption }
    temp.options[i].option_values.splice(j, 1)
    setProductOption(temp)
  }

  const handleBranchChange = (e: any, i: any) => {
    let temp = { ...productOption }
    temp.options[i][e.target.name] = e.target.value
    setProductOption(temp)
  }

  const handleContactChange = (e: any, i: any, j: any) => {
    let temp = { ...productOption }
    let data = {
      option_key: $(`#option_key_${i}_${j}`).val(),
      option_value: $(`#option_value_${i}_${j}`).val()
    };

    temp.options[i].option_values[j] = data;
    setProductOption(temp)
  }

  useEffect(() => {
    if (productOption?.options[0]?.option_name == "") {
      $('.option_key').val("");
      $('.option_value').val("");
      $('.option_key').prop("disabled", true);
      $('.option_value').prop("disabled", true);
    } else {
      $('.option_key').prop("disabled", false);
      $('.option_value').prop("disabled", false);
    }
    if(params.id){
      $('.option_name').prop("disabled", true);
      $('.option_value').prop("disabled", true);
      $('.option_key').prop("disabled", true);
    }
    if(!params?.id){
    let total = 0;
    for (let i = 0; i < productOption?.options[0]?.option_values?.length; i++) {
      total = total + Number(productOption?.options[0]?.option_values[i]?.option_value);
    }


    let total2 = $('#available_qty').val();
    if (total != Number(total2)) {
      $(".option_value").addClass("is-invalid");
      $(".option_value").removeClass("is-valid");
    } else if (total == Number(total2)) {
      $(".option_value").removeClass("is-invalid");
      $(".option_value").addClass("is-valid");
    }
  }

  }, [productOption, detail, availableQty]);


  return (
    <>
      <div className="page-header">
        <ToastContainer />
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-11">
              <div className="page-header-title">
                <h5 className="m-b-10">Product</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/product">Products</Link>
                </li>
                <li className="breadcrumb-item">
                  <a>{params.id ? "Edit " : "Add "}Product</a>
                </li>
              </ul>
            </div>
            <BackBtn />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>{params.id ? "Edit " : "Add "}Product</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <Formik
                    initialValues={
                      detail[ModuleConfig.ModuleConfig.primaryKey] === params.id
                        ? detail
                        : ModuleConfig.ModuleConfig.formDefaultValue
                    }
                    validate={(values) => {
                      const errors: any = {};
                      const fields = Object.keys(
                        ModuleConfig.ModuleConfig.formFields
                      );
                      for (let index = 0; index < fields.length; index++) {
                        if (
                          !values[fields[index]] &&
                          ModuleConfig.ModuleConfig.formFields[fields[index]]
                            .isRequired
                        ) {
                          errors[fields[index]] = "Required";
                        }
                      }

                      return errors;
                    }}
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          {Object.keys(
                            ModuleConfig.ModuleConfig.formFields
                          ).map((field: string, index: number) => {
                            const fieldDetails =
                              ModuleConfig.ModuleConfig.formFields[field];
                            return fieldDetails?.hideFor != profile?.role ? (
                              <div
                                key={`field_${index}`}
                                className={fieldDetails.colWidth}
                              >

                                {field === 'available_qty' ? <>
                                  <FormField
                                    name={field}
                                    readOnly={params.id ? fieldDetails?.readOnly : false}
                                    fieldDetails={fieldDetails}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    setFieldValue={setFieldValue}
                                    handleBlur={handleBlur}
                                    handleChange={(e: any) => {
                                      setFieldValue('available_qty', Number(e.target.value));
                                      setAvailableQty(e.target.value);
                                    }}
                                  />
                                </> : <>

                                  <FormField
                                    name={field}
                                    readOnly={
                                      params.id
                                        ? fieldDetails?.readOnly
                                        : false
                                    }
                                    fieldDetails={fieldDetails}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    setFieldValue={setFieldValue}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                  />
                                </>}

                              </div>
                            ) : null;
                          })}

                          <hr />

                          <div className="card-header">
                            <h5>Uploaded Images</h5>
                            <span>
                              Please click on submit button after upload all
                              images
                            </span>
                          </div>
                          <div className="image-section">
                            {detail.product_image?.length >= 1
                              ? detail.product_image.map(
                                (item: string, colIndex: number) => {
                                  return (
                                    <>
                                      <div
                                        className="image-box"
                                        data-id={
                                          detail?.product_image[colIndex]?.id
                                        }
                                      >
                                        <button
                                          className="me-2 trash-image"
                                          onClick={() =>
                                            deleteImageFromProduct(
                                              detail?.product_image[colIndex]
                                                ?.id || ""
                                            )
                                          }
                                        >
                                          <FaTrash />
                                        </button>
                                        <img
                                          src={`${Config.sourceImageURL}${detail?.product_image[colIndex]?.image}`}
                                          width="100px"
                                        />
                                      </div>
                                    </>
                                  );
                                }
                              )
                              : null}

                            {values?.image?.length >= 1
                              ? values?.image.map(
                                (item: string, colIndex: number) => {
                                  return (
                                    <>
                                      <div
                                        className="image-box"
                                        data-id={values?.image[colIndex]}
                                      >
                                        <span
                                          className="me-2 trash-image"
                                          onClick={() =>
                                            deleteImageFromProductArray(
                                              values?.image[colIndex] || "",
                                              values,
                                              colIndex
                                            )
                                          }
                                        >
                                          <FaTrash />
                                        </span>
                                        <img
                                          src={`${Config.sourceImageURL}${values?.image[colIndex]}`}
                                          width="100px"
                                        />
                                      </div>
                                    </>
                                  );
                                }
                              )
                              : null}
                          </div>

                              <hr />
                              {/* [Add More Option] Start */}
                              <div className="form-group mb-4">
                                <h5>Product Options</h5>
                                {/* <button
                              type="button"
                              className="btn btn-primary float-right mt--40"
                              onClick={addBranch}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                className="feather feather-plus-circle"
                              >
                                <circle cx="10" cy="10" r="8"></circle>
                                <line x1="10" y1="6" x2="10" y2="14"></line>
                                <line x1="6" y1="10" x2="14" y2="10"></line>
                              </svg>
                            </button> */}
                              </div>

                              <div className="form-group mb-4">
                                {productOption?.options.map((branch: any, i: any) => (
                                  <div className="border p-3 mb-4">

                                    <div className="row">
                                      <div className="col-md-11">
                                        <input name='option_name' className="form-control m-1 option_name" id={`option_name_${i}`} placeholder='Option Name' onChange={e => handleBranchChange(e, i)} value={productOption?.options[i].option_name} />
                                      </div>
                                      <div className="col-md-1">
                                        {/* <button type="button" className="btn btn-danger" onClick={e => deleteBranch(i)}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        className="feather feather-minus-circle"
                                      >
                                        <circle cx="10" cy="10" r="8" />
                                        <line
                                          x1="6"
                                          y1="10"
                                          x2="14"
                                          y2="10"
                                        />
                                      </svg>
                                    </button> */}
                                      </div>
                                    </div>

                                    {branch.option_values.map((contact: any, j: any) => (
                                      <div className="row" id="inputs_div">
                                        <div className="col-md-5">
                                          <input  name='option_key' className="form-control m-1 option_key" id={`option_key_${i}_${j}`} placeholder='Option Key' onChange={(e: any) => { handleContactChange(e, i, j) }} value={productOption?.options[i].option_values[j].option_key} />
                                        </div>
                                        <div className="col-md-5">
                                          <input type="number" name='option_value' className="form-control m-1 option_value" id={`option_value_${i}_${j}`} placeholder='Option Value' onChange={(e: any) => { handleContactChange(e, i, j) }} value={productOption?.options[i].option_values[j].option_value} />
                                        </div>
                                        {params.id ? <></>  :
                                        <div className="col-md-2">
                                        <button className="btn btn-danger" type="button" onClick={e => deleteContact(i, j)} >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="20"
                                              height="20"
                                              viewBox="0 0 20 20"
                                              fill="none"
                                              stroke="currentColor"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              className="feather feather-minus-circle"
                                            >
                                              <circle cx="10" cy="10" r="8" />
                                              <line
                                                x1="6"
                                                y1="10"
                                                x2="14"
                                                y2="10"
                                              />
                                            </svg>
                                          </button> 
                                        </div>}
                                      </div>
                                    ))}
                                    {params.id ? <></> :<button className="btn btn-primary " type="button" onClick={e => addContact(e, i)}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        className="feather feather-plus-circle"
                                      >
                                        <circle cx="10" cy="10" r="8"></circle>
                                        <line x1="10" y1="6" x2="10" y2="14"></line>
                                        <line x1="6" y1="10" x2="14" y2="10"></line>
                                      </svg>
                                    </button> }
                                  </div>
                                ))}
                              </div>
                              {/* [Add More Option] End */}
                        
                        </div>

                        <hr />
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={status === "loading"}
                        >
                          Submit
                        </button>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProductCreate;
