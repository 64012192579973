import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../stores';
import { BrandDetailInterface } from './models/BrandDetailInterface';
import { createBrand, deleteBrand, getBrandDetail, getBrandList, updateBrand } from './BrandAPI';

export interface BrandStateInterface {
  brands: BrandDetailInterface[];
  favourites: BrandDetailInterface[];
  totalRecord: number;
  status: 'idle' | 'loading' | 'failed' | 'created' | 'updated' | 'deleted';
  detail: BrandDetailInterface,
}

const initialState: BrandStateInterface = {
  brands: [],
  favourites: [],
  totalRecord: 0,
  status: 'idle',
  detail: {
    id: "",
    name: "",
    image: "",
    status: ""
  }
};

export const getBrandListAsync = createAsyncThunk(
  'brand/list',
  async (data : any) => {
    const response = await getBrandList(data);
    return response.data;
  }
);
export const getBrandDetailAsync = createAsyncThunk(
  'brand/detail',
  async (index: string) => {
    const response = await getBrandDetail(index);
    return response.data;
  }
);

export const createBrandAsync = createAsyncThunk(
  'brand/create',
  async (data: BrandDetailInterface) => {
    const response = await createBrand(data);
    return response.data;
  }
);

export const deleteBrandAsync = createAsyncThunk(
  'brand/delete',
  async (index: string) => {
    const response = await deleteBrand(index);
    return response.data;
  }
);

export const updateBrandAsync = createAsyncThunk(
  'brand/update',
  async (data: {
    index: string, data: BrandDetailInterface
  }) => {
    const response = await updateBrand(data.index, data.data);
    return response.data;
  }
);

export const brandSlice = createSlice({
  name: 'brand',
  initialState,
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getBrandListAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getBrandListAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.brands = action.payload.data;
        state.totalRecord = action.payload.total

      })
      .addCase(getBrandListAsync.rejected, (state) => {
        state.status = 'failed';
      })
      // GET DETAIL
      .addCase(getBrandDetailAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getBrandDetailAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.detail = action.payload;
      })
      .addCase(getBrandDetailAsync.rejected, (state) => {
        state.status = 'failed';
      })
      // CREATE
      .addCase(createBrandAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createBrandAsync.fulfilled, (state, action) => {
        state.status = 'created';
        state.detail = action.payload;
      })
      .addCase(createBrandAsync.rejected, (state) => {
        state.status = 'failed';
      })
      // UPDATE
      .addCase(updateBrandAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateBrandAsync.fulfilled, (state, action) => {
        state.status = 'updated';
        state.detail = action.payload;
      })
      .addCase(updateBrandAsync.rejected, (state) => {
        state.status = 'failed';
      })
      // DELETE
      .addCase(deleteBrandAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteBrandAsync.fulfilled, (state, action) => {
        state.status = 'deleted';
        state.detail = action.payload;
      })
      .addCase(deleteBrandAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {  } = brandSlice.actions;
export const BrandState = (state: RootState) => state.brand;
export default brandSlice.reducer;
