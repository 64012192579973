const ModuleConfig = {
    "primaryKey": "id",
    "formDefaultValue" : {
        "name" : "",
        "password" : ""
    },
    "formFields": {
        "name": {
            "label": "Name",
            "isRequired": false,
            "colWidth": "col-md-6",
            "inputType": "text",
            "type" :"text"
        },
        "email": {
            "label": "Email",
            "isRequired": false,
            "colWidth": "col-md-6",
            "inputType": "text",
            "type" :"text"
        },
        "mobile": {
            "label": "Mobile",
            "isRequired": false,
            "colWidth": "col-md-6",
            "inputType": "text",
            "type" :"number"
        },
        "password": {
            "label": "Password",
            "isRequired": false,
            "colWidth": "col-md-6",
            "inputType": "text",
            "type" :"password"
        },
        // "role": {
        //     "label": "Role",
        //     "isRequired": false,
        //     "colWidth": "col-md-6",
        //     "inputType": "select",
        //     "type" :"select",
        //     "placeholder" : "Select Role",
        //     "isAdd" : false,
        //     "options" : [{
        //         "label" : "User",
        //         "value" : "User"
        //     }, {
        //         "label" : "Admin",
        //         "value" : "Admin"
        //     }, {
        //         "label" : "Merchant",
        //         "value" : "Merchant"
        //     }]
        // },
        "status": {
            "label": "Status",
            "isRequired": false,
            "colWidth": "col-md-6",
            "inputType": "select",
            "type" :"select",
            "placeholder" : "Select Status",
            "isAdd" : false,
            "options" : [{
                "label" : "Active",
                "value" : "Active"
            }, {
                "label" : "Inactive",
                "value" : "Inactive"
            }, {
                "label" : "Pending",
                "value" : "Pending"
            }]
        }
       
    },
    "listColumnOrder": [
        "name",
        "email",
        "mobile",
        "status"
    ]
}

export default ModuleConfig