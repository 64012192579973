import AxiosInstance from "../../utils/AxiosInstance";
import { UserDetailInterface } from "./models/UserDetailInterface";

const API_ROUTE = "users";
export const getUserList = async (params: any) => {
  const prm = new URLSearchParams(params).toString();
  return await AxiosInstance.get(`/${API_ROUTE}?${prm}`);
};

export const getUserDetail = async (index: string) => {
  return await AxiosInstance.get(`/${API_ROUTE}/${index}`);
};

export const createUser = async (data: UserDetailInterface) => {
  const formData = new FormData();
  const keys = Object.keys(data);
  for (var i = 0; i < keys.length; i++) {
    formData.append(keys[i], data[keys[i]]);
  }

  return await AxiosInstance.post(`/${API_ROUTE}`, formData);
};

export const deleteUser = async (index: string) => {
  return await AxiosInstance.delete(`/${API_ROUTE}/${index}`);
};
export const updateUser = async (index: string, data: UserDetailInterface) => {
  const formData = new FormData();
  const keys = Object.keys(data);
  for (var i = 0; i < keys.length; i++) {
    formData.append(keys[i], data[keys[i]]);
  }
  return await AxiosInstance.post(`/${API_ROUTE}/${index}`, formData);
};
