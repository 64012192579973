import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../stores';
import { ContentDetailInterface } from './models/ContentDetailInterface';
import { createContent, deleteContent, getContentDetail, getContentList, updateContent } from './ContentAPI';

export interface ContentStateInterface {
  contents: ContentDetailInterface[];
  favourites: ContentDetailInterface[];
  totalRecord: number;
  status: 'idle' | 'loading' | 'failed' | 'created' | 'updated' | 'deleted';
  detail: ContentDetailInterface,
}

const initialState: ContentStateInterface = {
  contents: [],
  favourites: [],
  totalRecord: 0,
  status: 'idle',
  detail: {
    id: "",
    title: "",
    image: "",
    status: ""
  }
};

export const getContentListAsync = createAsyncThunk(
  'content/list',
  async (data : any) => {
    const response = await getContentList(data);
    return response.data;
  }
);
export const getContentDetailAsync = createAsyncThunk(
  'content/detail',
  async (index: string) => {
    const response = await getContentDetail(index);
    return response.data;
  }
);

export const createContentAsync = createAsyncThunk(
  'content/create',
  async (data: ContentDetailInterface) => {
    const response = await createContent(data);
    return response.data;
  }
);

export const deleteContentAsync = createAsyncThunk(
  'content/delete',
  async (index: string) => {
    const response = await deleteContent(index);
    return response.data;
  }
);

export const updateContentAsync = createAsyncThunk(
  'content/update',
  async (data: {
    index: string, data: ContentDetailInterface
  }) => {
    const response = await updateContent(data.index, data.data);
    return response.data;
  }
);

export const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
  },
  
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getContentListAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getContentListAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.contents = action.payload.data;
        state.totalRecord = action.payload.total

      })
      .addCase(getContentListAsync.rejected, (state) => {
        state.status = 'failed';
      })
      // GET DETAIL
      .addCase(getContentDetailAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getContentDetailAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.detail = action.payload;
      })
      .addCase(getContentDetailAsync.rejected, (state) => {
        state.status = 'failed';
      })
      // CREATE
      .addCase(createContentAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createContentAsync.fulfilled, (state, action) => {
        state.status = 'created';
        state.detail = action.payload;
      })
      .addCase(createContentAsync.rejected, (state) => {
        state.status = 'failed';
      })
      // UPDATE
      .addCase(updateContentAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateContentAsync.fulfilled, (state, action) => {
        state.status = 'updated';
        state.detail = action.payload;
      })
      .addCase(updateContentAsync.rejected, (state) => {
        state.status = 'failed';
      })
      // DELETE
      .addCase(deleteContentAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteContentAsync.fulfilled, (state, action) => {
        state.status = 'deleted';
        state.detail = action.payload;
      })
      .addCase(deleteContentAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {  } = contentSlice.actions;
export const ContentState = (state: RootState) => state.content;
export default contentSlice.reducer;
