const ModuleConfig = {
    "primaryKey": "id",
    "formDefaultValue" : {
        "name" : ""
    },
    "formFields": {
        "name": {
            "label": "Name",
            "isRequired": false,
            "colWidth": "col-md-6",
            "inputType": "text",
            "type" :"text"
        },
        "image": {
            "label": "Image",
            "isRequired": false,
            "colWidth": "col-md-6",
            "inputType": "file",
            "type" :"file"
        },
        "status": {
            "label": "Status",
            "isRequired": false,
            "colWidth": "col-md-6",
            "inputType": "select",
            "placeholder":"Select Status",
            "type" :"select",
            "options" : [{
                "label" : "Active",
                "value" : "Active"
            }, {
                "label" : "Inactive",
                "value" : "Inactive"
            }]
        }
    },
    "listColumnOrder": [
        "name",
        "image",
        "status"    
    ]
}

export default ModuleConfig