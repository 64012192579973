import { BrowserRouter } from "react-router-dom";
import AppRoute from "./routes/index";
import { Container, ThemeProvider } from "react-bootstrap";
import Header from "./modules/common/Header";
import Footer from "./modules/common/Footer";
import SideBar from "./modules/common/SideBar";
import "./assets/scss/index.scss";
// import "bootstrap/dist/css/bootstrap.min.css";

/**
 * Create Main App and configure
 * Theme
 * Router
 * Header
 * Main Body
 * Footer
 */
function App() {
  return (
    <div className="">
      <ThemeProvider
        breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
        minBreakpoint="xxs"
      >
        <BrowserRouter>
          <SideBar />
          <Header />
          {/* <div className="main-area"> */}
            <AppRoute />
          {/* </div> */}
          <Footer />
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
