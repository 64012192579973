import { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { appState } from "../../stores/appSlice";
import { useAppSelector } from "../../stores/hooks";
import AxiosInstance from "../../utils/AxiosInstance";
import MenuItems from "./menu.json";

const SideBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isMobileMenuOpen, profile } = useAppSelector(appState);
  const [sideBarCollapsed, setSideBarCollapsed] = useState(false);

  
  const signout = async () => {
    const res = await AxiosInstance.get("signout");
    if (res?.status === 200) {
      localStorage.clear();
      navigate("/signin");
      window.location.reload();
    }
  };


const NO_SIDEBAR_MENU = ["/signin", "/signin/", "/signup", "/signup/", "/order-reciept", "/order-reciept/", "/change-password"];

var pageUrl = location.pathname.split("/");

if(pageUrl[1] === "order-reciept"){
  return <></>;
}

  return  NO_SIDEBAR_MENU.indexOf(location.pathname) === -1 ? (
    <>
      {/* <div className="loader-bg">
        <div className="loader-track">
          <div className="loader-fill"></div>
        </div>
      </div> */}

      <nav className="pcoded-navbar navbar-collapsed">
        <div className="navbar-wrapper">
          <div className="navbar-brand header-logo">
            <a href="/" className="b-brand">
              <div className="b-bg">
                {/* <i className="feather icon-trending-up"></i> */}
                <img className="app-logo" src="/images/app-logo.png" alt="desk-logo"/>
              </div>
              <span className="b-title">COUPID</span>
            </a>

            <a className="mobile-menu" id="mobile-collapse" href="#!">
              <span></span>
            </a>
          </div>

          <div className="navbar-content scroll-div">
            <ul className="nav pcoded-inner-navbar">
              <li className="nav-item pcoded-menu-caption">
                <label>Navigation</label>
              </li>

              {MenuItems.map((menuItem, index) => {
                const isActive = location.pathname.indexOf(menuItem.link) === 0;
                return profile?.role === menuItem.role ? (
                  <li
                    key={`menuItem_${index}`}
                    className={`${isActive ? "nav-item active" : "nav-item"} 
                  ${menuItem.visible ? "" : "d-none"} ${
                      location.pathname === "/" && index === 0 ? "active" : ""
                    } `}
                  >
                    <Link to={menuItem.link} className="nav-link">
                      <span className="pcoded-micon">
                        <i className={menuItem.icon}></i>
                      </span>
                      <span className="pcoded-mtext">{menuItem.title}</span>
                    </Link>
                  </li>
                ) : null;
              })}
            </ul>
          </div>
        </div>
      </nav>
    </>
  ) : null;
};



export default SideBar;
