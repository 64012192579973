import { useEffect } from "react"
import { Formik } from "formik"
import AxiosInstance from "../utils/AxiosInstance"
import { useParams } from "react-router-dom"
import { useState } from "react"
import { Row } from "react-bootstrap"
import Config from "../utils/config"
import "react-toastify/dist/ReactToastify.css"
import { ToastContainer, toast } from "react-toastify"
import $ from "jquery";

const OrderReciept = () => {
  const params = useParams()
  const [orderInfoData, setOrderInfoData] = useState<any>(null)

  const getOrderInfo = async () => {
    const res = await AxiosInstance.get(`order/info/${params.id}`)
    if (res.status === 200) {
      setOrderInfoData(res?.data?.data)

      // $("meta[name='description']").attr("content", `${res?.data?.data?.user?.name} has sent you a gift!`);
      // $("meta[property='og:description']").attr("content", `${res?.data?.data?.user?.name} has sent you a gift!`);
      // $("meta[property='twitter:description']").attr("content", `${res?.data?.data?.user?.name} has sent you a gift!`);

      // const baseUrl = window.location.origin.toString();

      // $("meta[property='og:image']").attr("content", `${baseUrl}/images/app-logo.png`);
      // $("meta[property='twitter:image']").attr("content", `${baseUrl}/images/app-logo.png`);

      // $("meta[property='og:url']").attr("content", `${baseUrl}`);
      // $("meta[property='twitter:url']").attr("content", `${baseUrl}`);

    }
  }

  useEffect(() => {
    getOrderInfo()
  }, [])

  const onsubmit = async (values: any) => {
    if (!values.mobile || !values.address) {
      toast.error("Please enter valid mobile number and address.", {
        position: toast.POSITION.TOP_RIGHT,
      })
      return
    }
    if (orderInfoData?.products?.length !== values.option.length) {
      toast.error("Please select product options.", {
        position: toast.POSITION.TOP_RIGHT,
      })
      return
    }

    var data1 = [];
    for (var i = 0; values.option.length > i; i++) {
      data1.push(JSON.parse(values.option[i]));
    }

    const data = {
      mobile_number: values.mobile,
      address: values.address,
      options: JSON.stringify(data1)
    }

    const res = await AxiosInstance.post(`order/edit/${params.id}`, data)

    if (res?.status === 200) {
      toast.success("Delivery details updated successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      })
    } else {
      toast.error("Please enter valid Delivery details.", {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }

  const formatter = new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });


  return (
    <>
      <ToastContainer />
      <div className="order-reciept ">
        <div className="content">
          <div className="main-title">
            A surprise from {orderInfoData?.user?.name}
          </div>
          <div className="p-card">
            {orderInfoData?.products && orderInfoData?.products.length > 0
              ? orderInfoData?.products.map((prod: any, index: any) => {
                return (
                  <div className="p-item">
                    <img
                      src={`${Config.sourceImageURL}${prod?.product?.images[0].image}`}
                      alt=""
                    />
                    <div className="p-content">
                      <div className="p-name mb-1">
                        {prod?.product?.brand?.name}
                      </div>
                      <div className="p-desc mb-1">
                        {" "}
                        {prod?.product_name}{" "}
                      </div>
                      <div className="p-qty">Qty: {prod?.qty}</div>
                    </div>
                  </div>
                )
              })
              : null}
          </div>

          {orderInfoData?.note ? (
            <div className="w-card">
              <p>{orderInfoData?.note}</p>
            </div>
          ) : null}

          <div className="big-card">
            <div className="title">{orderInfoData?.merchant?.name} </div>
            <div className="Redemption-code-text">
              {" "}
              {orderInfoData?.order_type === "eGift"
                ? "Redemption code"
                : "Confirmation code"}
            </div>
            <div className="code-box">
              <div className="code-content">
                <div className="code-text ">
                  {orderInfoData?.confirmation_code}
                </div>
              </div>
            </div>
            <div className="text-center">
              Expires{" "}
              {orderInfoData?.link_expiry_date
                ? formatter
                  .format(Date.parse(orderInfoData?.link_expiry_date))
                  .toUpperCase()
                : ""}
            </div>
            <br />
            {orderInfoData?.order_type === "eGift" ? (
              <>
                <div className="w-card redeem-card">
                  <div className="redeeem-image-block">
                    <img src="/images/vector.png" alt="" />
                  </div>
                  <div className="redeeem-text-block">
                    <b>Reedem in Store</b> <br />{" "}
                    <p>
                      Provide the casheir with the redemption code and reedem
                      your gift!
                    </p>
                  </div>
                </div>
                <div className="center-line">
                  <img src="/images/feature_animation.png" alt="" />
                </div>
                <div className="title row redeem-at-div">
                  <div className="col-6 text-left tgrey">Reedemable at </div>
                  <div className="col-6 text-right">
                    {orderInfoData?.merchant?.name}{" "}
                  </div>
                </div>
                <div className="center-line">
                  <img src="/images/feature_animation.png" alt="" />
                </div>
                <div className="title row">
                  <span className="col-12 text-left tgrey">How to redeem </span>
                  <p className="redeem-note-text">
                    Gift can only be redeemed at physical stores. This does not
                    include counters in department stores or pop up stores.
                  </p>{" "}
                </div>
                <div className="center-line">
                  <img src="/images/feature_animation.png" alt="" />
                </div>
              </>
            ) : (
              <>
                <div className="center-line">
                  <img src="/images/feature_animation.png" alt="" />
                </div>

                <div className="form">
                  <div className="form-info-title">
                    Please provide the following :
                  </div>
                  <Formik
                    initialValues={{
                      mobile: orderInfoData?.mobile_number,
                      address: orderInfoData?.address,
                      option: [],
                    }}
                    enableReinitialize={true}
                    validate={(values: { mobile: string; address: string }) => {
                      const errors: { mobile?: string; address?: string } = {}
                      return errors
                    }}
                    onSubmit={onsubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="input-label mt-3">Mobile number</div>
                        <input
                          style={{ border: "1px solid grey", marginTop: "3px", minHeight: "37px" }}
                          className="form-control m-0"
                          type="tel"
                          name="mobile"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.mobile}
                          placeholder=""
                          autoFocus={true}
                          disabled={orderInfoData?.product_option_detail.length > 0}
                        />

                        <div className="input-label mt-3">Delivery address</div>
                        <input
                          style={{ border: "1px solid grey", marginTop: "3px", minHeight: "37px" }}
                          className="form-control m-0"
                          type="text"
                          name="address"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.address}
                          placeholder=""
                          autoFocus={true}
                          disabled={orderInfoData?.product_option_detail.length > 0}
                        />

                        <div className="input-label mt-3">Select an Option </div>
                        {orderInfoData?.products?.map((products: any, index: any) => {
                          return (
                            <>
                              <div className="input-label ms-1">{products.product_name}</div>
                              <select
                                className="form-control height24 m-0"
                                style={{ border: "1px solid grey", marginTop: "3px" }}
                                name={`option[${index}]`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.option[index]}
                                placeholder=""
                                autoFocus={true}
                                disabled={orderInfoData?.product_option_detail.length > 0}
                              >
                                <option value="">Select a {products?.product?.product_option_detail[0].option_name}</option>
                                {products?.product?.product_option_detail?.map((option: any, index: any) => {
                                  return (
                                    option?.qty != "0" || option?.qty > 0 ?
                                      <option selected={option?.isSelected == 1} value={JSON.stringify(option)}>{option.option_value}</option>
                                      : "");
                                })}
                              </select>
                            </>
                          );
                        })}

                        <div className="Receive-btn mt-3">
                          <button disabled={orderInfoData?.product_option_detail.length > 0} type="submit">{orderInfoData?.product_option_detail.length > 0 ? "Gift Received" : "Receive Gift"}</button>
                        </div>
                      </form>
                    )}
                  </Formik>
                  <br />
                </div>

                <div className="center-line">
                  <img src="/images/feature_animation.png" alt="" />
                </div>
              </>
            )}

            <div className="help-text">Need help?</div>
            <div className="image-content">
              <div>
                <div className="d-flex justify-content-center pt-2">
                  <img className="img-text" src="/images/image36.jpg" alt="" />
                </div>
                {/* <div className="d-flex justify-content-center pt-2">
                <img className="img-logo" src="/images/logo111.png" alt="" />
              </div> */}
                <div className="text-center pt-2">
                  contact : support@coupid.com.au
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OrderReciept
