import axios from "axios";
import { store } from "../stores";
import config from "./config";

const AxiosInstance = axios.create({
  baseURL: config.baseURL,
  timeout: 35000,
  headers: {
    Accept: "application/json",
    Authorization: "Bearer ",
    "content-type": "multipart/form-data",
  },
});


AxiosInstance.interceptors.request.use(
  async (conf: any) => {
    axios.defaults.timeout = 35000;

    let role = "admin/";
    if (store.getState().app.profile?.role) {
      role = store.getState().app.profile?.role;
    }
    conf.baseURL = config.baseURL + role?.toLowerCase() + "/";

    const token = localStorage.getItem("token");
    if (token) {
      conf.headers.Authorization = "Bearer ".concat(token);
    }
    return conf;
  },
  (error) => Promise.reject(error)
);


AxiosInstance.interceptors.response.use(
  async (response) => {
    return response;
  },
  (errors) => {
    return errors.response.data;
  }
);

export default AxiosInstance;
