import AxiosInstance from "../../utils/AxiosInstance"
import { MerchantDetailInterface } from "./models/MerchantDetailInterface"

const API_ROUTE = "merchants"
export const getMerchantList = async (params: any) => {
  const prm = new URLSearchParams(params).toString()
  return await AxiosInstance.get(`/${API_ROUTE}?${prm}`)
}

export const getMerchantDetail = async (index: string) => {
  return await AxiosInstance.get(`/${API_ROUTE}/${index}`)
}

export const createMerchant = async (data: MerchantDetailInterface) => {
  const formData = new FormData()
  const keys = Object.keys(data)

  for (var i = 0; i < keys.length; i++) {
    if (keys[i] === "merchant_detail") {
      formData.append(keys[i], JSON.stringify(data[keys[i]]))
    } else {
      formData.append(keys[i], data[keys[i]])
    }
  }

  return await AxiosInstance.post(`/${API_ROUTE}`, formData)
}

export const deleteMerchant = async (index: string) => {
  return await AxiosInstance.delete(`/${API_ROUTE}/${index}`)
}

export const updateMerchant = async (
  index: string,
  data: MerchantDetailInterface
) => {
  const formData = new FormData()
  const keys = Object.keys(data)
  formData.append("logo", data.merchant_detail.logo)
  for (var i = 0; i < keys.length; i++) {
    formData.append(keys[i], data[keys[i]])
  }

  return await AxiosInstance.post(`/${API_ROUTE}/${index}`, formData)
}
