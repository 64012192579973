import { getPropByString } from "../../../utils/CommonFunction";
import config from "../../../utils/config";

const FileInput = (props: any) => {

  var values = getPropByString(props?.values, props?.name);
  if (values) {
    if (values?.name) {
      values = URL.createObjectURL(values);
    } else {
      values = config.sourceImageURL + values;
    }
  }

  return (
    <div className="input-group">
      <input
        type={"file"}
        id={props.name}
        className="form-control"
        onBlur={props.handleBlur}
        // value={getPropByString(props?.values, props?.name)}
        onChange={(event) => {
          if (event?.currentTarget?.files) {
            props.setFieldValue(props.name, event?.currentTarget?.files[0]);
          } else {
            props.setFieldValue(props.name, null);
          }
        }}
        aria-describedby="basic-addon2"
      />

      {values ? (
        <span className="input-group-text" id="basic-addon2">
          <a href={values} target="_blank" rel="noopener noreferrer">
            <i className="feather icon-eye"></i>
          </a>
        </span>
      ) : null}
    </div>
  );
};

export default FileInput;
